import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  FormikForm,
  FormikSelect,
  FormikSubmitButton,
  FormikTextField,
  useBackdropContext,
} from "@nc/neoscloud-common-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useHandleError } from "Hooks/useHandleError";
import { SecurityGroup, SecurityGroupRule } from "Services/api/securityGroups/interfaces";
import {
  createSecurityGroupRule,
  deleteSecurityGroupRule,
  getSecurityGroup,
} from "Services/api/securityGroups/securityGroups";
import { Query } from "Shared/Query/Query";
import { confirmWrapper } from "Utils/confirmWrapper";
import { Formik } from "formik";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { securityGroupRuleSchema } from "./validations";

export function SecurityGroup() {
  const { id } = useParams();
  const result = useQuery({
    queryKey: [getSecurityGroup.name, id],
    queryFn: async () => {
      const { status, data } = await getSecurityGroup(id as string);
      if (status !== "success") throw "Error fetching security group!";

      return data;
    },
  });

  return (
    <Query result={result} onSuccess={(securityGroup) => <SecurityGroupSection securityGroup={securityGroup} />} />
  );
}

interface SecurityGroupSectionProps {
  securityGroup: SecurityGroup;
}

function SecurityGroupSection({ securityGroup }: SecurityGroupSectionProps) {
  const { id, name, rules } = securityGroup;
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(searchParams.get("add") === "true");
  const [, setBackdropState] = useBackdropContext();
  const { enqueueSnackbar } = useSnackbar();
  const handleError = useHandleError();
  const queryClient = useQueryClient();

  const { mutateAsync: deleteRule } = useMutation({
    mutationFn: async (ruleId: string) => {
      setBackdropState({ open: true, msg: "Deleting rule..." });
      const { status, data } = await deleteSecurityGroupRule(id, ruleId);

      if (status !== "success") throw data;
      enqueueSnackbar(data, { variant: "success" });
      securityGroup.rules = securityGroup.rules.filter((rule) => rule.id !== ruleId);
      queryClient.setQueryData<SecurityGroup>([getSecurityGroup.name, id], securityGroup);
    },
    onError: handleError,
    onSettled: () => {
      setBackdropState({ open: false, msg: "" });
    },
  });

  return (
    <section>
      <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="space-between">
        <h2>Security group rules: {name}</h2>
        <Button variant="contained" onClick={() => setOpen(true)}>
          Add Rule
        </Button>
      </Stack>
      {rules.length > 0 ? (
        <Table sx={{ minWidth: 550, textAlign: "center" }} aria-label="Security group rules">
          <TableHead>
            <TableRow>
              <TableCell>Direction</TableCell>
              <TableCell>Port Range</TableCell>
              <TableCell>Remote IP Prefix</TableCell>
              <TableCell>Description</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rules.map((rule) => (
              <SecurityGroupRuleRow key={rule.id} rule={rule} onDelete={() => void deleteRule(rule.id)} />
            ))}
          </TableBody>
        </Table>
      ) : (
        <div>
          There are no rules for security group{" "}
          <strong>
            <em>{name}</em>
          </strong>
        </div>
      )}
      <AddSecurityGroupRuleDialog
        open={open}
        securityGroupId={id}
        onClose={() => setOpen(false)}
        onCreate={(securityGroupRule) => {
          securityGroup.rules.push(securityGroupRule);
          queryClient.setQueryData<SecurityGroup>([getSecurityGroup.name, id], securityGroup);
          setOpen(false);
        }}
      />
    </section>
  );
}

interface SecurityGroupRuleProps {
  rule: SecurityGroupRule;
  onDelete: () => void;
}

function SecurityGroupRuleRow({
  rule: { direction, port_range_min, port_range_max, remote_ip_prefix, description },
  onDelete,
}: SecurityGroupRuleProps) {
  const confirm = useConfirm();
  return (
    <TableRow>
      <TableCell>{direction}</TableCell>
      {port_range_min === port_range_max ? (
        <TableCell>{port_range_min}</TableCell>
      ) : (
        <TableCell>
          {port_range_min} - {port_range_max}
        </TableCell>
      )}
      <TableCell>{remote_ip_prefix}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell>
        <Button
          id="delete-button"
          color="error"
          variant="contained"
          onClick={() =>
            confirmWrapper(
              confirm({
                title: "Delete security group rule",
                description: "Are you sure you want to remove this rule?",
                confirmationText: "Delete",
              }),
              onDelete
            )
          }
        >
          Delete
        </Button>
      </TableCell>
    </TableRow>
  );
}

interface AddSecurityGroupRuleDialogProps {
  open: boolean;
  securityGroupId: string;
  onClose: () => void;
  onCreate: (securityGroupRule: SecurityGroupRule) => void;
}

function AddSecurityGroupRuleDialog({ open, securityGroupId, onClose, onCreate }: AddSecurityGroupRuleDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Add Rule</DialogTitle>
      <Formik
        initialValues={{
          description: "",
          direction: "ingress",
          openPort: "port",
          fromPort: "",
          toPort: "",
          remoteIpPrefix: "0.0.0.0/0",
        }}
        validationSchema={securityGroupRuleSchema}
        onSubmit={async (inputs, { setSubmitting }) => {
          try {
            if (inputs.openPort === "port") inputs.toPort = inputs.fromPort;
            const { status, data } = await createSecurityGroupRule(securityGroupId, inputs);
            if (status !== "success") {
              const { message } = data;
              enqueueSnackbar(message, { variant: "error" });
              return;
            }
            onCreate(data);
            enqueueSnackbar("Security group rule created successfully!", { variant: "success" });
          } catch (error) {
            enqueueSnackbar("Error creating security group rule!", { variant: "error" });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ values: { openPort } }) => (
          <FormikForm width="100%">
            <DialogContent>
              <Stack spacing={2}>
                <FormikTextField
                  name="description"
                  id="description"
                  label="Description"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                />
                <FormikSelect name="direction" id="direction" label="Direction">
                  <MenuItem value="ingress">Ingress</MenuItem>
                  <MenuItem value="egress">Egress</MenuItem>
                </FormikSelect>
                <FormikSelect name="openPort" id="openPort" label="Open Port">
                  <MenuItem value="port">Port</MenuItem>
                  <MenuItem value="range">Range</MenuItem>
                </FormikSelect>
                <FormikTextField
                  name="fromPort"
                  id="fromPort"
                  label="From Port"
                  variant="outlined"
                  fullWidth
                  type="number"
                />
                {openPort === "range" && (
                  <FormikTextField
                    name="toPort"
                    id="toPort"
                    label="To Port"
                    variant="outlined"
                    fullWidth
                    type="number"
                  />
                )}

                <FormikTextField name="remoteIpPrefix" id="remoteIpPrefix" label="CIDR" variant="outlined" fullWidth />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <FormikSubmitButton variant="contained" submittingText="Adding...">
                Create
              </FormikSubmitButton>
            </DialogActions>
          </FormikForm>
        )}
      </Formik>
    </Dialog>
  );
}
