import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useState } from "react";
import { SelectVolume, SelectVolumeProps } from "Shared/SelectVolume/SelectVolume";

export default function AdditionalDiskSpaceSection({
  storages,
  onChange,
  storagePricePerGB,
  storageMaxHours,
}: SelectVolumeProps) {
  const [addAdditionalStorage, setAddAdditionalStorage] = useState(false);

  return (
    <section>
      <h2>Add Additional Disk Space</h2>
      <section>
        <FormControl>
          <RadioGroup
            row
            name="additional-storage-group"
            value={addAdditionalStorage}
            onChange={({ target: { value } }) => {
              const shouldAddStorage = value === "true";
              setAddAdditionalStorage(shouldAddStorage);
              onChange({
                size: shouldAddStorage && storages.length > 0 ? storages[0].size : 0,
                error: false,
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label={"Yes"} />
            <FormControlLabel value={false} control={<Radio />} label={"No"} />
          </RadioGroup>
        </FormControl>
      </section>
      {addAdditionalStorage && (
        <SelectVolume
          storages={storages}
          storageMaxHours={storageMaxHours}
          storagePricePerGB={storagePricePerGB}
          onChange={onChange}
        />
      )}
    </section>
  );
}
