import enhancedFetch from "Utils/enhancedFetch";
import { API_URL } from "Utils/envVariables";
import { fetchDefaultConfigs } from "../config";
import {
  CreateKeyPairPayload,
  CreateKeyPairResponse,
  DeleteKeyPairResponse,
  GetUserKeysResponse,
  createKeyPairResponseDecoder,
  deleteKeyPairResponseDecoder,
  getUserKeysResponseDecoder,
} from "./interfaces";

const URL = `${API_URL}/keypairs`;

export async function getUserKeys(): Promise<GetUserKeysResponse> {
  const response = await enhancedFetch(`${URL}/`, {
    ...fetchDefaultConfigs(),
    method: "GET",
  });

  return getUserKeysResponseDecoder.verify(await response.json());
}

export async function createKeyPair(payload: CreateKeyPairPayload): Promise<CreateKeyPairResponse> {
  const response = await enhancedFetch(`${URL}/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify(payload),
  });

  return createKeyPairResponseDecoder.verify(await response.json());
}

export async function deleteKeyPair(id: string): Promise<DeleteKeyPairResponse> {
  const response = await enhancedFetch(`${URL}/${id}/`, {
    ...fetchDefaultConfigs(),
    method: "DELETE",
  });

  return deleteKeyPairResponseDecoder.verify(await response.json());
}
