import { array, DecoderType, nullable, object, string } from "decoders";
import { jsendResponseDecoder, jsendSuccessDecoder } from "Utils/jsend";
import { errorKeyResponseDecoder } from "../interfaces";

const listKeyDecoder = object({
  id: string,
  name: string,
  fingerprint: string,
});

export type ListKey = DecoderType<typeof listKeyDecoder>;

export const getUserKeysResponseDecoder = jsendResponseDecoder(array(listKeyDecoder), string);

export type GetUserKeysResponse = DecoderType<typeof getUserKeysResponseDecoder>;

export interface CreateKeyPairPayload {
  name: string;
  public_key?: string;
}

const createKeyPairDecoder = object({
  id: string,
  name: string,
  fingerprint: string,
  private_key: nullable(string),
});

export type CreateKeyPair = DecoderType<typeof createKeyPairDecoder>;

export const createKeyPairResponseDecoder = jsendResponseDecoder(createKeyPairDecoder, errorKeyResponseDecoder);

export type CreateKeyPairResponse = DecoderType<typeof createKeyPairResponseDecoder>;

export const deleteKeyPairResponseDecoder = jsendSuccessDecoder(string);

export type DeleteKeyPairResponse = DecoderType<typeof deleteKeyPairResponseDecoder>;
