import { array, boolean, DecoderType, integer, number, object, string } from "decoders";
import { jsendResponseDecoder, jsendSuccessDecoder } from "Utils/jsend";
import { errorKeyResponseDecoder } from "../interfaces";

const instanceDecoder = object({
  id: string,
  name: string,
  status: string,
  ip: string,
  image: object({
    distribution: string,
    version: string,
  }),
  plan: object({
    cpu: string,
    memory: string,
    disk: string,
    price: string,
  }),
  networks: array(
    object({
      version: string,
      public: boolean,
      ip: string,
    })
  ),
  volumes: array(
    object({
      id: string,
    })
  ),
  tags: array(string),
});

export type Instance = DecoderType<typeof instanceDecoder>;

export const instanceResponseDecoder = jsendResponseDecoder(instanceDecoder, string);

export type InstanceResponse = DecoderType<typeof instanceResponseDecoder>;

export const instancesResponseDecoder = jsendResponseDecoder(array(instanceDecoder), string);

export type InstancesResponse = DecoderType<typeof instancesResponseDecoder>;

export const startInstanceResponseDecoder = jsendSuccessDecoder(string);
export const stopInstanceResponseDecoder = jsendSuccessDecoder(string);
export const deleteInstanceResponseDecoder = jsendSuccessDecoder(string);

export type StartInstanceResponse = DecoderType<typeof startInstanceResponseDecoder>;
export type StopInstanceResponse = DecoderType<typeof stopInstanceResponseDecoder>;
export type DeleteInstanceResponse = DecoderType<typeof deleteInstanceResponseDecoder>;

const distributionVersionDecoder = object({
  id: string,
  display: string,
  minRam: integer,
  minDisk: integer,
});

export type DistributionVersion = DecoderType<typeof distributionVersionDecoder>;

const distributionDecoder = object({
  name: string,
  display: string,
  versions: array(distributionVersionDecoder),
});

export type Distribution = DecoderType<typeof distributionDecoder>;

const planOptionDecoder = object({
  id: integer,
  flavorId: string,
  name: string,
  costPerMonth: number,
  costPerHour: number,
  cpu: string,
  memory: object({ amount: integer, display: string }),
  disk: object({ amount: integer, display: string }),
});

export type PlanOption = DecoderType<typeof planOptionDecoder>;

const planDecoder = object({
  name: string,
  description: string,
  options: array(planOptionDecoder),
});

export type Plan = DecoderType<typeof planDecoder>;

const storagePlanDecoder = object({
  id: integer,
  name: string,
  size: integer,
  costPerMonth: number,
  costPerHour: number,
});

export type StoragePlan = DecoderType<typeof storagePlanDecoder>;

const appDecoder = { id: number, name: string, version: string, os: string, os_version: string, logo: string };
const createInstanceOptionsDecoder = object({
  distributions: array(distributionDecoder),
  apps: array(object(appDecoder)),
  plans: array(planDecoder),
  storages: array(storagePlanDecoder),
  storagePricePerGB: number,
  storageMaxHours: number,
  keys: array(object({ id: string, name: string })),
});

export type CreateInstanceOptions = DecoderType<typeof createInstanceOptionsDecoder>;

export const createInstanceOptionsResponseDecoder = jsendSuccessDecoder(createInstanceOptionsDecoder);

export type CreateInstanceOptionsResponse = DecoderType<typeof createInstanceOptionsResponseDecoder>;

export interface CreateInstancePayload {
  flavorId: string;
  imageType: "os" | "app";
  imageId: string | number;
  keyId: string;
  name: string;
  storage: number;
  tags: string[];
}

export const createInstanceResponseDecoder = jsendResponseDecoder(string, errorKeyResponseDecoder);

export type CreateInstanceResponse = DecoderType<typeof createInstanceResponseDecoder>;

const securityGroupOptionsDecoder = object({
  available: array(object({ id: string, name: string })),
  assigned: array(object({ id: string, name: string })),
});

export type SecurityGroupOptions = DecoderType<typeof securityGroupOptionsDecoder>;

export const securityGroupOptionsResponseDecoder = jsendSuccessDecoder(securityGroupOptionsDecoder);

export type SecurityGroupOptionsResponse = DecoderType<typeof securityGroupOptionsResponseDecoder>;

export const updateSecurityGroupResponseDecoder = jsendResponseDecoder(string, string);

export type UpdateSecurityGroupResponse = DecoderType<typeof updateSecurityGroupResponseDecoder>;

const appDetailsDecoder = object({
  ...appDecoder,
  details: string,
  categories: array(object({ id: number, name: string })),
});

export type AppDetails = DecoderType<typeof appDetailsDecoder>;

export const appDetailsResponseDecoder = jsendSuccessDecoder(appDetailsDecoder);

export type AppDetailsResponse = DecoderType<typeof appDetailsResponseDecoder>;

export type InstanceCreateWS = {
  type: string;
  instance: Instance;
};
