import { array, boolean, DecoderType, number, object, string } from "decoders";
import { jsendSuccessDecoder } from "Utils/jsend";

export const addCardResponseDecoder = jsendSuccessDecoder(string);

export type AddCardResponse = DecoderType<typeof addCardResponseDecoder>;

const pendingInvoiceDecoder = object({
  id: string,
  subscription: string,
  name: string,
  amount_due: number,
  subtotal: number,
  total: number,
  type: string,
  created: string,
  removal_date: string,
});

export type PendingInvoiceDecoder = DecoderType<typeof pendingInvoiceDecoder>;

const pendingItems = array(pendingInvoiceDecoder);

const upcomingItems = array(
  object({
    subscription: string,
    cancel_at_period_end: boolean,
    name: string,
    amount_due: number,
    subtotal: number,
    total: number,
  })
);

const paymentMethodDecoder = object({
  id: string,
  brand: string,
  last4: string,
});

const paymentMethods = array(paymentMethodDecoder);

const billingDetailsDecoder = object({
  pending: pendingItems,
  upcoming: object({
    amount_due: number,
    subtotal: number,
    total: number,
    payment_due: string,
    machines: upcomingItems,
    volumes: upcomingItems,
  }),
  payment_methods: paymentMethods,
});

export type BillingDetails = DecoderType<typeof billingDetailsDecoder>;

export const billingDetailsResponseDecoder = jsendSuccessDecoder(billingDetailsDecoder);

export type BillingDetailsResponse = DecoderType<typeof billingDetailsResponseDecoder>;

export interface PayInvoicePayload {
  invoiceId: string;
  paymentMethodId: string;
}

export const hasPendingInvoiceResponseDecoder = jsendSuccessDecoder(boolean);

export type HasPendingInvoiceResponse = DecoderType<typeof hasPendingInvoiceResponseDecoder>;
