import { Box, Chip, TextField } from "@mui/material";
import { useState } from "react";

export default function AddTagsSection({ onChange }: { onChange: (tags: Set<string>) => void }) {
  const [tags, setTags] = useState<Set<string>>(new Set<string>());

  const onTagInput = ({ target }: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    const tagRegex = /^[a-zA-Z0-9:\-_]*$/; // Only has letters, numbers, colons, dashes, and underscores.
    if (target.value && tagRegex.test(target.value) && !tags.has(target.value)) {
      const newTags = new Set([...tags, target.value]);
      setTags(newTags);
      onChange(newTags);
    }
    target.value = "";
  };

  return (
    <section id="add-tag-section">
      <h2>Add tags</h2>
      <p>
        Use tags to organize and relate resources. Tags may contain letters, numbers, colons, dashes, and underscores.
      </p>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          p: 0.5,
        }}
      >
        {Array.from(tags).map((tag) => {
          return (
            <Chip
              sx={{ m: "8px" }}
              key={tag}
              label={tag}
              data-testid={tag}
              onDelete={() => {
                const newTags = new Set(Array.from(tags));
                newTags.delete(tag);
                setTags(newTags);
                onChange(newTags);
              }}
              className="tag"
            />
          );
        })}
      </Box>
      <TextField
        size="medium"
        type="text"
        variant="outlined"
        onBlur={onTagInput}
        onKeyPress={(event) => {
          if (event.key === "Enter") onTagInput(event as unknown as React.FocusEvent<HTMLInputElement>);
        }}
        required
        fullWidth
        placeholder="Type tags here"
        inputProps={{ "data-testid": "add-tag" }}
      ></TextField>
    </section>
  );
}
