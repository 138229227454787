import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Link } from "@mui/material";
import { setStripeKey, useBackdropContext } from "@nc/neoscloud-common-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useHandleError } from "Hooks/useHandleError";
import { useInvalidateQueries } from "Hooks/useInvalidateQueries";
import { createInstance, getCreateInstanceOptions } from "Services/api/instances/instances";
import { CreateInstanceOptions } from "Services/api/instances/interfaces";
import { ListKey } from "Services/api/keypairs/interfaces";
import { getUserKeys } from "Services/api/keypairs/keypairs";
import { useAddPaymentDialogContext } from "Shared/AddPaymentDialogContext/AddPaymentDialogContext";
import { Query } from "Shared/Query/Query";
import { STRIPE_KEY } from "Utils/envVariables";
import { useSnackbar } from "notistack";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddTagsSection from "./AddTagsSection";
import AdditionalDiskSpaceSection from "./AdditionalDiskSpaceSection";
import AuthenticationSection from "./AuthenticationSection";
import ChooseImageSection from "./ChooseImageSection";
import ChooseMachineNameSection from "./ChooseMachineNameSection";
import ChoosePlanSection from "./ChoosePlanSection";
import { FormData } from "./interfaces";

void setStripeKey(STRIPE_KEY);

export default function CreateMachine() {
  const result = useQuery({
    queryKey: [getCreateInstanceOptions.name],
    queryFn: async () => {
      const { data } = await getCreateInstanceOptions();

      if (data.distributions.length < 1) throw new Error("No distributions available");
      for (const dist of data.distributions) if (dist.versions.length < 1) throw new Error("No versions available");

      if (data.plans.length < 1) throw new Error("No plans available");
      for (const plan of data.plans) if (plan.options.length < 1) throw new Error("No plan instances available");

      return data;
    },
  });

  return <Query result={result} onSuccess={(options) => <MachineForm options={options} />} />;
}

function MachineForm({ options }: { options: CreateInstanceOptions }) {
  const { distributions, apps, plans, storages, storagePricePerGB, storageMaxHours, keys } = options;
  const [formData, setFormData] = useState<FormData>({
    image: {
      type: "os",
      os: { name: distributions[0].name, version: distributions[0].versions[0] },
      app: { id: 0, name: "" },
    },
    plan: { name: plans[0].name, option: plans[0].options[0] },
    machineName: {
      error: false,
      value: generateMachineName(distributions[0].name, plans[0].name, plans[0].options[0].id),
    },
    storage: {
      size: 0,
      error: false,
    },
    tags: [],
    auth: {
      type: "ssh",
      sshKey: "",
      error: true,
    },
  });
  const [sshKeys, setSshKeys] = useState<CreateInstanceOptions["keys"]>(keys);
  const [generatedName, setGeneratedName] = useState(
    generateMachineName(distributions[0].name, plans[0].name, plans[0].options[0].id)
  );
  const [error, setError] = useState("");
  const [, setBackdropState] = useBackdropContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const handleError = useHandleError();
  const queryClient = useQueryClient();
  const invalidateQueries = useInvalidateQueries();
  const [openQuotaDialog, setOpenQuotaDialog] = useState(false);

  const onFormDataChange = (formData: FormData) => {
    setFormData(formData);
    setGeneratedName(
      generateMachineName(
        formData.image.type === "os" ? formData.image.os.name : formData.image.app.name,
        formData.plan.name,
        formData.plan.option.id
      )
    );

    const {
      image: { os },
      plan,
    } = formData;

    setError("");
    if (plan.option.disk.amount < os.version.minDisk)
      setError(
        "Image is too big for the disk or RAM size of the picked plan. " +
          "You can try either to change the image or the plan."
      );
    else if (formData.image.type === "app" && formData.image.app.id === 0)
      setError("You must select an application to continue");
  };

  const { mutateAsync: submit, isLoading } = useMutation({
    mutationFn: async () => {
      setBackdropState({ open: true, msg: "Creating machine..." });
      setError("");
      const { status, data } = await createInstance({
        name: formData.machineName.value,
        imageType: formData.image.type,
        imageId: formData.image.type === "os" ? formData.image.os.version.id : formData.image.app.id,
        flavorId: formData.plan.option.flavorId,
        keyId: formData.auth.type === "ssh" ? formData.auth.sshKey : "",
        storage: formData.storage.size,
        tags: formData.tags,
      });

      if (status == "success") {
        invalidateQueries("create-machine");
        enqueueSnackbar(data, { variant: "success" });
        setBackdropState({ open: false, msg: "" });
        navigate("/");
      } else {
        if (data.errorKey === "userNoPaymentMethod") {
          enqueueSnackbar(data.message, { variant: "warning" });
          setAddPaymentDialogState(true);
        } else if (data.errorKey === "pendingInvoice") {
          enqueueSnackbar(data.message, { variant: "error" });
          navigate("/billing");
        } else if (data.errorKey === "quotaExceeded") {
          enqueueSnackbar(data.message, { variant: "error" });
          setOpenQuotaDialog(true);
        } else {
          enqueueSnackbar("Error creating the machine", { variant: "error" });
          setError(data.message);
        }
      }
    },
    onError: handleError,
    onSettled: () => {
      setBackdropState({ open: false, msg: "" });
    },
  });

  const [, setAddPaymentDialogState] = useAddPaymentDialogContext(submit);

  const disableSubmit: boolean =
    formData.storage.error || formData.machineName.error || formData.auth.error || Boolean(error) || isLoading;

  return (
    <Fragment>
      <h1>Create Machine</h1>
      <ChooseImageSection
        distributions={distributions}
        apps={apps}
        onChange={(image) => onFormDataChange({ ...formData, image })}
      />
      <ChoosePlanSection plans={plans} onChange={(plan) => onFormDataChange({ ...formData, plan })} />
      <AdditionalDiskSpaceSection
        storages={storages}
        storagePricePerGB={storagePricePerGB}
        storageMaxHours={storageMaxHours}
        onChange={(data) => {
          onFormDataChange({ ...formData, storage: { ...data } });
        }}
      />
      <ChooseMachineNameSection
        generatedName={generatedName}
        onChange={(name, error) => onFormDataChange({ ...formData, machineName: { error, value: name } })}
      />
      <AuthenticationSection
        sshKeys={sshKeys}
        onChange={(data) => {
          onFormDataChange({ ...formData, auth: { ...data } });
        }}
        onKeyCreation={(keyData) => {
          const keys = [...sshKeys, { id: keyData.id, name: keyData.name }];
          setSshKeys(keys);
          queryClient.setQueryData<ListKey[]>([getUserKeys.name], (old) => {
            if (old) return [...old, keyData];
            return [keyData];
          });
          queryClient.setQueryData<CreateInstanceOptions>([getCreateInstanceOptions.name], { ...options, keys });
        }}
      />
      <AddTagsSection onChange={(tags) => onFormDataChange({ ...formData, tags: Array.from(tags) })} />
      <Box component="section" sx={{ p: "30px 0" }}>
        <Button variant="contained" fullWidth disabled={disableSubmit} onClick={() => void submit()}>
          {isLoading ? "Creating machine..." : "Create Machine"}
        </Button>
        <FormHelperText error={Boolean(error)}> {error}</FormHelperText>
      </Box>
      <QuotaExceededDialog open={openQuotaDialog} onClose={() => setOpenQuotaDialog(false)} />
    </Fragment>
  );
}

function generateMachineName(distroName: string, planName: string, planOption: string | number) {
  return `${distroName.toLowerCase()}-${planName.toLowerCase()}-${planOption}`;
}

interface QuotaExceededDialogProps {
  open: boolean;
  onClose: () => void;
}

const QuotaExceededDialog = (props: QuotaExceededDialogProps) => {
  const { onClose, open } = props;
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle color={"darkred"}>Capacity exceeded</DialogTitle>
      <DialogContent>
        <p>
          Oops! Looks like we have reached a capacity limit on our end when trying to create your machine. We sent a
          notification to our development team and we will be working on increasing it soon.
        </p>
        <p>
          Please wait some time before retrying or if you keep receiving this error you can go to{" "}
          <Link target="_blank" rel="noopener" href="https://neoscloudllc.com/contact-us/">
            our contact page
          </Link>{" "}
          to notify our support team and we will gladly help you.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
