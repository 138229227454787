import { Box, Grid, Link } from "@mui/material";
import { FormContainer, LoginForm, LoginState } from "@nc/neoscloud-common-react";
import { useQuery } from "@tanstack/react-query";
import { useHandleError } from "Hooks/useHandleError";
import logo from "Public/LockLogo.png";
import { login, neosAccountLogin } from "Services/api/auth/auth";
import { FailedLoginResponse } from "Services/api/auth/interfaces";
import { NEOSACCOUNT_URL } from "Utils/envVariables";
import { JsendFail } from "Utils/jsend";
import { showSnackbarErrorMessage, showSnackbarErrorsObject } from "Utils/snackbar";
import { ProviderContext, useSnackbar } from "notistack";
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom";

export function Login() {
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const handleError = useHandleError();

  const { isLoading } = useQuery({
    queryKey: [neosAccountLogin.name],
    queryFn: async () => {
      const neosAccountToken = searchParams.get("neosAccountToken");

      if (neosAccountToken) {
        const result = await neosAccountLogin(neosAccountToken);

        if (result.status === "fail") {
          handleFailedLogin(result, enqueueSnackbar);
          return null;
        }

        enqueueSnackbar(result.data, {
          variant: "success",
        });

        navigate("/");
        return null;
      }

      return null;
    },
    onError: handleError,
  });

  if (isLoading) return <></>;

  return (
    <FormContainer
      title="Sign in to Neos2cloud"
      logo={
        <Box
          src={logo}
          sx={{
            width: "60px",
            paddingBottom: "20px",
          }}
          component="img"
          alt="NeosAccount logo"
        />
      }
    >
      <LoginPageForm />
    </FormContainer>
  );
}

function LoginPageForm() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  return (
    <LoginForm
      onLogin={async ({ username, password, keepSignedIn }: LoginState) => {
        await onLogin(username, password, keepSignedIn, enqueueSnackbar, navigate);
      }}
    >
      <Grid item xs>
        <Link href={`${NEOSACCOUNT_URL}/restore/`} variant="body2">
          Forgot password?
        </Link>
      </Grid>
      <Grid item>
        <Link href={`${NEOSACCOUNT_URL}/signup/`} variant="body2">
          {"Don't have an account? Sign Up"}
        </Link>
      </Grid>
    </LoginForm>
  );
}

async function onLogin(
  username: string,
  password: string,
  keepSignedIn: boolean,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  navigate: NavigateFunction
) {
  const result = await login(username, password, keepSignedIn);

  if (result.status === "fail") {
    handleFailedLogin(result, enqueueSnackbar);
    return;
  }

  enqueueSnackbar(result.data.message, {
    variant: "success",
  });

  navigate("/");
}

function handleFailedLogin(
  result: JsendFail<FailedLoginResponse>,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"]
) {
  result.data.match({
    left: showSnackbarErrorsObject(enqueueSnackbar),
    right: showSnackbarErrorMessage(enqueueSnackbar),
  });
}
