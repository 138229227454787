import Cookies from "js-cookie";

export const fetchDefaultConfigs = (): RequestInit => ({
  headers: {
    "Content-Type": "application/json",
    "X-CSRFToken": getCSRFToken(),
  },
});

export function getCSRFToken() {
  return Cookies.get("csrftoken") || "";
}
