import { Visibility, VisibilityOff, Warning } from "@mui/icons-material";
import ReportIcon from "@mui/icons-material/Report";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { CreateInstanceOptions } from "Services/api/instances/interfaces";
import { CreateKeyPair } from "Services/api/keypairs/interfaces";
import { AddSshKeyDialog } from "Shared/AddSshKeyDialog/AddSshKeyDialog";
import { useEffect, useState } from "react";
import { FormData } from "./interfaces";

type Authentication = "ssh" | "password";

export default function AuthenticationSection({
  sshKeys,
  onChange,
  onKeyCreation,
}: {
  sshKeys: CreateInstanceOptions["keys"];
  onChange: (data: FormData["auth"]) => void;
  onKeyCreation: (keyData: CreateKeyPair) => void;
}) {
  const [authType, setAuthType] = useState<Authentication>("ssh");

  return (
    <section>
      <h2>Authentication</h2>
      <section>
        <FormControl>
          <RadioGroup
            row
            name="authentication-group"
            value={authType}
            onChange={({ target: { value } }) => {
              if (value === "password" || value === "ssh") setAuthType(value);
            }}
          >
            <FormControlLabel value={"ssh"} control={<Radio />} label={"SSH keys"} />
            <FormControlLabel value={"password"} control={<Radio />} label={"Password"} />
          </RadioGroup>
        </FormControl>
      </section>
      {authType === "ssh" ? (
        <SshSection sshKeys={sshKeys} onChange={onChange} onKeyCreation={onKeyCreation} />
      ) : (
        <PasswordSection authType={authType} onChange={onChange} />
      )}
    </section>
  );
}

function SshSection({
  sshKeys,
  onChange,
  onKeyCreation,
}: {
  sshKeys: CreateInstanceOptions["keys"];
  onChange: (data: FormData["auth"]) => void;
  onKeyCreation: (keyData: CreateKeyPair) => void;
}) {
  const [selectedSshKey, setSelectedSshKey] = useState<string>("");
  const [errorInSSH, setErrorInSSH] = useState(isErrorInSSH(selectedSshKey));

  const [openNewSshKeyDialog, setOpenNewSshKeyDialog] = useState(false);

  useEffect(() => {
    onChange({ type: "ssh", sshKey: selectedSshKey, error: errorInSSH });
  }, [selectedSshKey, errorInSSH]);

  return (
    <section>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <h3>Choose your SSH key</h3>

        {errorInSSH && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ReportIcon sx={{ pl: "8px", color: "#ca0c0c" }} />
            <span style={{ paddingLeft: "8px", color: "#ca0c0c" }}>Select a key.</span>
          </Box>
        )}
      </Box>

      <FormControl>
        <RadioGroup
          row
          name="ssh-key-group"
          value={selectedSshKey}
          onChange={({ target: { value } }) => {
            setSelectedSshKey(value);
            setErrorInSSH(isErrorInSSH(value));
          }}
        >
          {sshKeys.map((key) => {
            return (
              <FormControlLabel sx={{ mr: "25px" }} key={key.id} control={<Radio />} label={key.name} value={key.id} />
            );
          })}
        </RadioGroup>
      </FormControl>

      <Button variant="outlined" onClick={() => setOpenNewSshKeyDialog(true)}>
        New SSH Key
      </Button>
      <AddSshKeyDialog
        open={openNewSshKeyDialog}
        handleClose={() => setOpenNewSshKeyDialog(false)}
        onKeyCreation={onKeyCreation}
      />
    </section>
  );
}

function PasswordSection({ authType, onChange }: { authType: string; onChange: (data: FormData["auth"]) => void }) {
  const [password, setPassword] = useState("");
  const [[errorInPassword, passwordErrors], setErrorInPassword] = useState<[boolean, string[]]>([true, []]);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    errorInPassword
      ? onChange({ type: "password", password: "", error: errorInPassword })
      : onChange({ type: "password", password: password, error: errorInPassword });
  }, [authType, password, errorInPassword]);

  return (
    <section>
      <TextField
        sx={{
          maxWidth: "520px",
          width: "100%",
        }}
        label="Create root password"
        size="medium"
        type={showPassword ? "text" : "password"}
        variant="outlined"
        value={password}
        onChange={({ target: { value } }) => {
          setPassword(value);
          setErrorInPassword(isErrorInPassword(value));
        }}
        margin="dense"
        required={authType === "password"}
        error={errorInPassword}
        placeholder="Type your password..."
        data-testid="password-textfield"
        helperText={
          errorInPassword ? (
            <span>
              {passwordErrors.map((error) => (
                <span key={error}>
                  <span>- {error}</span> <br />
                </span>
              ))}
            </span>
          ) : null
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      ></TextField>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Warning />
        <p style={{ paddingLeft: "8px" }}>
          Please store your password securely. You will not be sent an email containing the machine details or password.
        </p>
      </Box>
    </section>
  );
}

function isErrorInSSH(selectedSshKey: string) {
  return selectedSshKey === "";
}

function isErrorInPassword(password: string): [boolean, string[]] {
  const errors: string[] = [];
  const uppercaseRegex = /[A-Z]+/; // Contain one or more uppercase letters
  const numberRegex = /[0-9]+/; // Contain one or more numbers
  if (password === "") errors.push("Password is required");
  if (password.length < 8) errors.push("Must be at least 8 characters long");
  if (!uppercaseRegex.test(password)) errors.push("Must contain 1 or more uppercase letters");
  if (!numberRegex.test(password)) errors.push("Must contain 1 or more numbers");
  return [errors.length > 0, errors];
}
