import { useBackdropContext } from "@nc/neoscloud-common-react";
import { useQueryClient } from "@tanstack/react-query";
import { useInvalidateQueries } from "Hooks/useInvalidateQueries";
import { Machine } from "Pages/Dashboard/Machine";
import { getUserInstances, startInstance, stopInstance } from "Services/api/instances/instances";
import { Instance } from "Services/api/instances/interfaces";
import { getMachineBillingDetails } from "Services/api/neosaccount/neosaccount";
import { Volume } from "Services/api/volumes/interfaces";
import { getAvailableUserVolumes, getUserVolumes } from "Services/api/volumes/volumes";
import { AddVolumeDialog } from "Shared/AddVolumeDialog/AddVolumeDialog";
import { EditSecurityGroupsDialog } from "Shared/EditSecurityGroupsDialog/EditSecurityGroupsDialog";
import { useSnackbar } from "notistack";
import { Fragment, useEffect, useState } from "react";
import { DialogState, MachineStatus } from "./interfaces";

const defaultState: DialogState = {
  instance: {
    id: "",
    name: "",
  },
  dialog: "close",
};

export function Machines({ instances }: { instances: Instance[] }): JSX.Element {
  const [machines, setMachines] = useState(instances);
  useEffect(() => setMachines(instances), [instances]);
  const [dialogState, setDialogState] = useState<DialogState>(defaultState);
  const invalidateQueries = useInvalidateQueries();
  const queryClient = useQueryClient();
  const [, setBackdropState] = useBackdropContext();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <section>
      <h2>Machines</h2>

      {machines.length > 0 ? (
        <Fragment>
          {machines.map((machine) => (
            <Machine
              instance={machine}
              key={machine.id}
              setDialogState={setDialogState}
              onUpdate={updateMachine}
              onDelete={(id) => {
                const instances = machines.filter((machine) => machine.id !== id);
                setMachines(instances);
                queryClient.setQueryData<Instance[]>([getUserInstances.name], instances);
                invalidateQueries("delete-machine");
              }}
              onRefetch={(instance) => {
                setMachines(machines.map((machine) => (machine.id === instance.id ? instance : machine)));
              }}
            />
          ))}
          {dialogState.dialog === "addVolume" && (
            <AddVolumeDialog
              open
              handleClose={() => setDialogState(defaultState)}
              onAdd={(volume) => {
                if (!volume) return;
                setDialogState(defaultState);
                queryClient.setQueryData<Volume[]>([getUserVolumes.name], (prev) => {
                  if (!prev) return prev;
                  return [...prev, volume];
                });
                void queryClient.invalidateQueries({
                  queryKey: [getUserInstances.name],
                  exact: true,
                });
                void queryClient.invalidateQueries({
                  queryKey: [getAvailableUserVolumes.name],
                  exact: true,
                });
                void queryClient.invalidateQueries({
                  queryKey: [getMachineBillingDetails.name],
                  exact: true,
                });
              }}
              onAttach={() => setDialogState(defaultState)}
              instanceId={dialogState.instance.id}
            />
          )}
          {dialogState.dialog === "editSecurityGroups" && (
            <EditSecurityGroupsDialog
              instanceId={dialogState.instance.id}
              onClose={() => setDialogState(defaultState)}
            />
          )}
        </Fragment>
      ) : (
        <div>You don&apos;t have any machines...</div>
      )}
    </section>
  );

  async function updateMachine(id: string, status: MachineStatus) {
    if (status === MachineStatus.ACTIVE) {
      setBackdropState({ open: true, msg: "Starting machine..." });
      const { data } = await startInstance(id);
      enqueueSnackbar(data, { variant: "success" });
    } else {
      setBackdropState({ open: true, msg: "Stopping machine..." });
      const { data } = await stopInstance(id);
      enqueueSnackbar(data, { variant: "success" });
    }

    setMachines(machines.map((machine) => (machine.id === id ? { ...machine, status: status } : machine)));
    setBackdropState({ open: false, msg: "" });
  }
}
