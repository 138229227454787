import { DecoderType, object, string } from "decoders";
import { jsendResponseDecoder } from "Utils/jsend";

export const userData = {
  username: string,
  first_name: string,
  last_name: string,
  email: string,
  full_name: string,
};

const userProfileData = {
  ...userData,
  ...{ avatar: string },
};

export const userDataDecoder = object(userProfileData);

export const getCurrentUserResponseDecoder = jsendResponseDecoder(userDataDecoder, string);

export type GetCurrentUserResponse = DecoderType<typeof getCurrentUserResponseDecoder>;
