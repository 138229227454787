import { TextField } from "@mui/material";
import { useEffect, useState } from "react";

export default function ChooseMachineNameSection({
  generatedName,
  onChange,
}: {
  generatedName: string;
  onChange: (machineName: string, error: boolean) => void;
}) {
  const [machineName, setMachineName] = useState<string>(generatedName);
  const [errorInMachineName, setErrorInMachineName] = useState(isErrorInMachineName(generatedName));
  const [userChangedMachineName, setUserChangedMachineName] = useState(false);
  useEffect(() => {
    if (!userChangedMachineName) {
      setMachineName(generatedName);
      setErrorInMachineName(isErrorInMachineName(generatedName));
    }
  }, [generatedName]);

  useEffect(() => {
    errorInMachineName ? onChange("", errorInMachineName) : onChange(machineName, errorInMachineName);
  }, [machineName, errorInMachineName]);

  return (
    <section>
      <h2>Choose a name</h2>
      <p>
        Give your Machine an identifying name you will remember them by. Your Machine name can only contain alphanumeric
        characters, dashes, and periods.
      </p>
      <TextField
        fullWidth
        size="medium"
        type="text"
        variant="outlined"
        value={machineName}
        onChange={({ target: { value } }) => {
          setMachineName(value);
          setErrorInMachineName(isErrorInMachineName(value));
          setUserChangedMachineName(true);
        }}
        required
        error={errorInMachineName}
        placeholder="Enter Machine Name"
        helperText={errorInMachineName ? "Invalid machine name" : ""}
        data-testid="machine-name-textfield"
      ></TextField>
    </section>
  );
}

function isErrorInMachineName(name: string) {
  if (name === "") return true;
  const machineNameRegex = /^[a-zA-Z0-9.-]*$/; // Only has alphanumeric characters, dashes, and periods.
  if (!machineNameRegex.test(name)) return true;
  return false;
}
