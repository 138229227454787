import enhancedFetch from "Utils/enhancedFetch";
import { API_URL } from "Utils/envVariables";
import { fetchDefaultConfigs } from "../config";
import {
  ErrorKeyJsendResponse,
  StringJsendResponse,
  errorKeyJsendResponseDecoder,
  stringJsendResponseDecoder,
} from "../interfaces";
import {
  CreateSecurityGroupPayload,
  CreateSecurityGroupResponse,
  CreateSecurityGroupRuleResponse,
  GetSecurityGroupResponse,
  GetUserSecurityGroupsResponse,
  SecurityGroupRulePayload,
  UpdateSecurityGroupPayload,
  createSecurityGroupResponseDecoder,
  createSecurityGroupRuleResponseDecoder,
  getSecurityGroupResponseDecoder,
  getUserSecurityGroupsResponseDecoder,
} from "./interfaces";

export async function getUserSecurityGroups(): Promise<GetUserSecurityGroupsResponse> {
  const response = await enhancedFetch(`${API_URL}/security-groups/`, {
    ...fetchDefaultConfigs(),
    method: "GET",
  });

  return getUserSecurityGroupsResponseDecoder.verify(await response.json());
}

export async function getSecurityGroup(id: string): Promise<GetSecurityGroupResponse> {
  const response = await enhancedFetch(`${API_URL}/security-groups/${id}/`, {
    ...fetchDefaultConfigs(),
    method: "GET",
  });

  return getSecurityGroupResponseDecoder.verify(await response.json());
}

export async function createSecurityGroup(payload: CreateSecurityGroupPayload): Promise<CreateSecurityGroupResponse> {
  const response = await enhancedFetch(`${API_URL}/security-groups/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify(payload),
  });

  return createSecurityGroupResponseDecoder.verify(await response.json());
}

export async function updateSecurityGroup({
  id,
  ...payload
}: UpdateSecurityGroupPayload): Promise<CreateSecurityGroupResponse> {
  const response = await enhancedFetch(`${API_URL}/security-groups/${id}/`, {
    ...fetchDefaultConfigs(),
    method: "PUT",
    body: JSON.stringify(payload),
  });

  return createSecurityGroupResponseDecoder.verify(await response.json());
}

export async function deleteSecurityGroup(id: string): Promise<ErrorKeyJsendResponse> {
  const response = await enhancedFetch(`${API_URL}/security-groups/${id}/`, {
    ...fetchDefaultConfigs(),
    method: "DELETE",
  });

  return errorKeyJsendResponseDecoder.verify(await response.json());
}

export async function createSecurityGroupRule(
  id: string,
  payload: SecurityGroupRulePayload
): Promise<CreateSecurityGroupRuleResponse> {
  const response = await enhancedFetch(`${API_URL}/security-groups/${id}/rules/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify(payload),
  });

  return createSecurityGroupRuleResponseDecoder.verify(await response.json());
}

export async function deleteSecurityGroupRule(id: string, ruleId: string): Promise<StringJsendResponse> {
  const response = await enhancedFetch(`${API_URL}/security-groups/${id}/rules/${ruleId}/`, {
    ...fetchDefaultConfigs(),
    method: "DELETE",
  });

  return stringJsendResponseDecoder.verify(await response.json());
}
