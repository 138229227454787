import enhancedFetch from "Utils/enhancedFetch";
import { API_URL } from "Utils/envVariables";
import { fetchDefaultConfigs } from "../config";
import {
  LoginNeosAccountResponse,
  loginNeosAccountResponseDecoder,
  LoginResponse,
  loginResponseDecoder,
  LogoutResponse,
  logoutResponseDecoder,
} from "./interfaces";

export async function login(username: string, password: string, keepSignedIn: boolean): Promise<LoginResponse> {
  const response = await enhancedFetch(`${API_URL}/auth/login/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify({
      username,
      password,
      keepSignedIn,
    }),
  });

  return loginResponseDecoder.verify(await response.json());
}

export async function neosAccountLogin(token: string): Promise<LoginNeosAccountResponse> {
  const response = await enhancedFetch(`${API_URL}/auth/neosaccount/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify({
      token: token,
    }),
  });

  return loginNeosAccountResponseDecoder.verify(await response.json());
}

export async function logout(): Promise<LogoutResponse> {
  const response = await enhancedFetch(`${API_URL}/auth/logout/`, {
    ...fetchDefaultConfigs(),
    method: "GET",
  });

  return logoutResponseDecoder.verify(await response.json());
}
