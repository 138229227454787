import enhancedFetch from "Utils/enhancedFetch";
import { API_URL } from "Utils/envVariables";
import { fetchDefaultConfigs } from "../config";
import {
  AppDetailsResponse,
  CreateInstanceOptionsResponse,
  InstanceResponse,
  SecurityGroupOptionsResponse,
  appDetailsResponseDecoder,
  createInstanceOptionsResponseDecoder,
  instanceResponseDecoder,
  securityGroupOptionsResponseDecoder,
} from "./interfaces";

import { StringJsendResponse, stringJsendResponseDecoder } from "../interfaces";
import {
  CreateInstancePayload,
  CreateInstanceResponse,
  DeleteInstanceResponse,
  InstancesResponse,
  StartInstanceResponse,
  StopInstanceResponse,
  createInstanceResponseDecoder,
  deleteInstanceResponseDecoder,
  instancesResponseDecoder,
  startInstanceResponseDecoder,
  stopInstanceResponseDecoder,
} from "./interfaces";

export async function getUserInstance(id: string): Promise<InstanceResponse> {
  const response = await enhancedFetch(`${API_URL}/instances/${id}/`, {
    ...fetchDefaultConfigs(),
    method: "GET",
  });

  return instanceResponseDecoder.verify(await response.json());
}

export async function getUserInstances(): Promise<InstancesResponse> {
  const response = await enhancedFetch(`${API_URL}/instances/`, {
    ...fetchDefaultConfigs(),
    method: "GET",
  });

  return instancesResponseDecoder.verify(await response.json());
}

export async function startInstance(id: string): Promise<StartInstanceResponse> {
  const response = await enhancedFetch(`${API_URL}/instances/${id}/start/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
  });

  return startInstanceResponseDecoder.verify(await response.json());
}

export async function stopInstance(id: string): Promise<StopInstanceResponse> {
  const response = await enhancedFetch(`${API_URL}/instances/${id}/stop/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
  });

  return stopInstanceResponseDecoder.verify(await response.json());
}

export async function deleteInstance(id: string): Promise<DeleteInstanceResponse> {
  const response = await enhancedFetch(`${API_URL}/instances/${id}/`, {
    ...fetchDefaultConfigs(),
    method: "DELETE",
  });

  return deleteInstanceResponseDecoder.verify(await response.json());
}

export async function getCreateInstanceOptions(): Promise<CreateInstanceOptionsResponse> {
  const response = await enhancedFetch(`${API_URL}/instances/options/`, {
    ...fetchDefaultConfigs(),
    method: "GET",
  });

  return createInstanceOptionsResponseDecoder.verify(await response.json());
}

export async function createInstance(payload: CreateInstancePayload): Promise<CreateInstanceResponse> {
  const response = await enhancedFetch(`${API_URL}/instances/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify(payload),
  });

  return createInstanceResponseDecoder.verify(await response.json());
}

export async function getInstanceSecurityGroupOptions(id: string): Promise<SecurityGroupOptionsResponse> {
  const response = await enhancedFetch(`${API_URL}/instances/${id}/security-group-options/`, {
    ...fetchDefaultConfigs(),
    method: "GET",
  });

  return securityGroupOptionsResponseDecoder.verify(await response.json());
}

export async function updateInstanceSecurityGroups(id: string, securityGroups: string[]): Promise<StringJsendResponse> {
  const response = await enhancedFetch(`${API_URL}/instances/${id}/security-groups/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify({ security_groups: securityGroups }),
  });

  return stringJsendResponseDecoder.verify(await response.json());
}

export async function getAppDetails(id: string | number): Promise<AppDetailsResponse> {
  const response = await enhancedFetch(`${API_URL}/instances/app-details/${id}/`, {
    ...fetchDefaultConfigs(),
    method: "GET",
  });

  return appDetailsResponseDecoder.verify(await response.json());
}
