import { jsendResponseDecoder } from "Utils/jsend";
import { DecoderType, string } from "decoders";

import { object } from "decoders";

export const errorKeyResponseDecoder = object({
  errorKey: string,
  message: string,
});

export type ErrorKeyResponse = DecoderType<typeof errorKeyResponseDecoder>;

export const errorKeyJsendResponseDecoder = jsendResponseDecoder(string, errorKeyResponseDecoder);

export type ErrorKeyJsendResponse = DecoderType<typeof errorKeyJsendResponseDecoder>;

export const stringJsendResponseDecoder = jsendResponseDecoder(string, string);

export type StringJsendResponse = DecoderType<typeof stringJsendResponseDecoder>;
