import enhancedFetch from "Utils/enhancedFetch";
import { API_URL } from "Utils/envVariables";
import { fetchDefaultConfigs } from "../config";
import {
  AttachVolumeRequest,
  AttachVolumeResponse,
  CreateUserVolumesResponse,
  CreateVolumeRequest,
  DeleteVolumeResponse,
  DetachVolumeRequest,
  DetachVolumeResponse,
  GetUserVolumesResponse,
  VolumeOptionsResponse,
  attachVolumeResponseDecoder,
  createUserVolumesResponseDecoder,
  deleteVolumeResponseDecoder,
  detachVolumeResponseDecoder,
  getUserVolumesResponseDecoder,
  volumeOptionsResponseDecoder,
} from "./interfaces";

export async function getUserVolumes(): Promise<GetUserVolumesResponse> {
  const response = await enhancedFetch(`${API_URL}/volumes/`, {
    ...fetchDefaultConfigs(),
    method: "GET",
  });

  return getUserVolumesResponseDecoder.verify(await response.json());
}

export async function getAvailableUserVolumes(): Promise<GetUserVolumesResponse> {
  const response = await enhancedFetch(`${API_URL}/volumes/available_volumes/`, {
    ...fetchDefaultConfigs(),
    method: "GET",
  });

  return getUserVolumesResponseDecoder.verify(await response.json());
}

export async function createVolume(payload: CreateVolumeRequest): Promise<CreateUserVolumesResponse> {
  const response = await enhancedFetch(`${API_URL}/volumes/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify(payload),
  });

  return createUserVolumesResponseDecoder.verify(await response.json());
}

export async function deleteVolume(id: string): Promise<DeleteVolumeResponse> {
  const response = await enhancedFetch(`${API_URL}/volumes/${id}/`, {
    ...fetchDefaultConfigs(),
    method: "DELETE",
  });

  return deleteVolumeResponseDecoder.verify(await response.json());
}

export async function attachVolume(payload: AttachVolumeRequest): Promise<AttachVolumeResponse> {
  const response = await enhancedFetch(`${API_URL}/volumes/attach/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify(payload),
  });

  return attachVolumeResponseDecoder.verify(await response.json());
}

export async function detachVolume(payload: DetachVolumeRequest): Promise<DetachVolumeResponse> {
  const response = await enhancedFetch(`${API_URL}/volumes/detach/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify(payload),
  });

  return detachVolumeResponseDecoder.verify(await response.json());
}

export async function getCreateVolumeOptions(): Promise<VolumeOptionsResponse> {
  const response = await enhancedFetch(`${API_URL}/volumes/options/`, {
    ...fetchDefaultConfigs(),
    method: "GET",
  });

  return volumeOptionsResponseDecoder.verify(await response.json());
}
