import * as Yup from "yup";

export const securityGroupSchema = Yup.object({
  name: Yup.string().required(),
  description: Yup.string(),
});

export const securityGroupRuleSchema = Yup.object({
  description: Yup.string().required("Required").max(255),
  direction: Yup.string().required().oneOf(["ingress", "egress"]),
  openPort: Yup.string().required("Required").oneOf(["port", "range"]),
  fromPort: Yup.number().required("Required").min(1, "Min: 1").max(65535, "Max: 65535"),
  toPort: Yup.number().when("openPort", {
    is: "range",
    then: Yup.number()
      .required("Required")
      .min(Yup.ref("fromPort"), ({ min }) => `Min: ${min}`)
      .max(65535, "Max: 65535"),
  }),
  remoteIpPrefix: Yup.string().required("Required"),
});
