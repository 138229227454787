import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Machines } from "Pages/Dashboard/Machines";
import { getUserInstances } from "Services/api/instances/instances";
import { InstanceCreateWS } from "Services/api/instances/interfaces";
import { Query } from "Shared/Query/Query";
import { USER_WS_URL } from "Utils/envVariables";
import { useEffect } from "react";
import useWebSocket from "react-use-websocket";

export function Dashboard() {
  const queryClient = useQueryClient();
  const result = useQuery({
    queryKey: [getUserInstances.name],
    queryFn: async () => {
      const { status, data } = await getUserInstances();

      if (status !== "success") throw "Error getting instances";

      return data;
    },
  });
  const { lastJsonMessage: event } = useWebSocket<InstanceCreateWS>(USER_WS_URL, {
    share: true,
  });

  useEffect(() => {
    if (event?.type === "instance.created") {
      queryClient.setQueryData(
        [getUserInstances.name],
        result?.data?.map((instance) => (instance.id === event.instance.id ? event.instance : instance))
      );
    }
  }, [event]);

  return (
    <Query
      result={result}
      OnLoading={() => <div>Loading machines...</div>}
      onSuccess={(instances) => <Machines instances={instances} />}
    />
  );
}
