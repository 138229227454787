import { jsendResponseDecoder } from "Utils/jsend";
import { DecoderType, array, either, nullable, object, positiveInteger, string } from "decoders";
import { errorKeyResponseDecoder } from "../interfaces";

const baseSecurityGroup = {
  id: string,
  name: string,
  description: string,
};

const securityGroupRule = {
  id: string,
  direction: string,
  security_group_id: string,
  port_range_min: nullable(positiveInteger),
  port_range_max: nullable(positiveInteger),
  remote_ip_prefix: nullable(string),
  normalized_cidr: nullable(string),
  description: nullable(string),
};

const listSecurityGroupDecoder = object(baseSecurityGroup);

export type ListSecurityGroup = DecoderType<typeof listSecurityGroupDecoder>;

const securityGroupRuleDecoder = object(securityGroupRule);

export type SecurityGroupRule = DecoderType<typeof securityGroupRuleDecoder>;

const securityGroupDecoder = object({ ...baseSecurityGroup, rules: array(securityGroupRuleDecoder) });

export type SecurityGroup = DecoderType<typeof securityGroupDecoder>;

export const getUserSecurityGroupsResponseDecoder = jsendResponseDecoder(array(listSecurityGroupDecoder), string);

export type GetUserSecurityGroupsResponse = DecoderType<typeof getUserSecurityGroupsResponseDecoder>;

export const getSecurityGroupResponseDecoder = jsendResponseDecoder(securityGroupDecoder, string);

export type GetSecurityGroupResponse = DecoderType<typeof getSecurityGroupResponseDecoder>;

export type CreateSecurityGroupPayload = Pick<ListSecurityGroup, "name" | "description">;

export type UpdateSecurityGroupPayload = Pick<ListSecurityGroup, "id" | "name" | "description">;

export const createSecurityGroupResponseDecoder = jsendResponseDecoder(securityGroupDecoder, string);

export type CreateSecurityGroupResponse = DecoderType<typeof createSecurityGroupResponseDecoder>;

const securityGroupRulePayloadDecoder = object({
  direction: string,
  fromPort: either(positiveInteger, string),
  toPort: either(positiveInteger, string),
  remoteIpPrefix: string,
  description: string,
});

export type SecurityGroupRulePayload = DecoderType<typeof securityGroupRulePayloadDecoder>;

export const createSecurityGroupRuleResponseDecoder = jsendResponseDecoder(
  securityGroupRuleDecoder,
  errorKeyResponseDecoder
);

export type CreateSecurityGroupRuleResponse = DecoderType<typeof createSecurityGroupRuleResponseDecoder>;
