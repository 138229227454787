import { useState } from "react";

export function useDrawerToggle() {
  const [mobileOpen, setMobileOpen] = useState(false);

  /* istanbul ignore next */
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return { mobileOpen, setMobileOpen, handleDrawerToggle };
}
