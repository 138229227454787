import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { constant, either } from "decoders";
import { saveAs } from "file-saver";
import { useHandleError } from "Hooks/useHandleError";
import { useSnackbar } from "notistack";
import { useCallback, useRef, useState } from "react";
import { CreateKeyPair } from "Services/api/keypairs/interfaces";
import { createKeyPair } from "Services/api/keypairs/keypairs";

export function AddSshKeyDialog({
  open,
  handleClose = () => {},
  onKeyCreation = () => {},
}: {
  open: boolean;
  handleClose?: () => void;
  onKeyCreation?: (keyData: CreateKeyPair) => void;
}) {
  const [action, setAction] = useState<"import" | "create">("import");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [content, setContent] = useState("");
  const [contentError, setContentError] = useState(false);
  const fileInput = useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const handleError = useHandleError();

  const resetState = useCallback(() => {
    setAction("import");
    setName("");
    setNameError(false);
    setContent("");
    setContentError(false);
  }, []);

  const { mutateAsync: addKey, status: addKeyStatus } = useMutation({
    mutationFn: async () => {
      const isImport = action === "import";
      const { status, data } = isImport
        ? await createKeyPair({ name, public_key: content })
        : await createKeyPair({ name });

      if (status === "success") {
        enqueueSnackbar(isImport ? "Key added successfully" : "Key pair created successfully", {
          variant: "success",
        });
        if (!isImport && data.private_key) {
          saveAs(new Blob([data.private_key], { type: "text/plain;charset=utf-8" }), `${data.name}.cer`);
        }

        resetState();
        onKeyCreation(data);
        handleClose();
      } else enqueueSnackbar(data.message, { variant: "error" });
    },
    onError: handleError,
  });

  const onFileChange = async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const files = target.files;
    if (!files || files.length === 0) return;

    const content = await getFileString(files[0]);
    setContent(content);
    setContentError(!/^ssh-rsa .*/.test(content));

    function getFileString(file: File): Promise<string> {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = function () {
          resolve(reader.result as string);
        };
        reader.readAsText(file);
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>Add SSH key</DialogTitle>
      <DialogContent>
        <FormControl>
          <RadioGroup
            row
            name="key-action-group"
            value={action}
            onChange={({ target: { value } }) => {
              setAction(either(constant("import"), constant("create")).verify(value));
            }}
          >
            <FormControlLabel value={"import"} control={<Radio />} label={"Add public key"} />
            <FormControlLabel value={"create"} control={<Radio />} label={"Generate new key pair"} />
          </RadioGroup>
        </FormControl>
        {action === "import" ? (
          <>
            <p>You may copy your public SSH key and paste it in the space below or copy it from a file.</p>
            <Button variant="contained" onClick={() => fileInput.current?.click()}>
              Copy from file
            </Button>
            <TextField
              name="File Input"
              label="File Input"
              fullWidth
              sx={{ display: "none" }}
              inputProps={{ ref: fileInput }}
              type="file"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => void onFileChange(e)}
            />
            <TextField
              label="SSH Key Content"
              multiline
              rows={4}
              fullWidth
              sx={{ mt: "20px" }}
              error={contentError}
              helperText={contentError ? "SSH key content must be a valid SSH key" : ""}
              value={content}
              onChange={({ target: { value } }) => {
                setContent(value);
                setContentError(!/^ssh-rsa .*/.test(value));
              }}
            />
          </>
        ) : (
          <p>You may generate a new key pair and store the private key safely in your computer.</p>
        )}

        <TextField
          label="Name"
          fullWidth
          sx={{ mt: "20px" }}
          value={name}
          onChange={({ target: { value } }) => {
            setName(value);
            setNameError(!value);
          }}
          error={nameError}
          helperText={
            nameError
              ? "Name cannot be blank."
              : "Name can only have digits, ascii letters, dashes, underscores and spaces"
          }
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {action === "import" ? (
          <Button
            disabled={!name || !content || nameError || contentError || addKeyStatus === "loading"}
            type="submit"
            variant="contained"
            onClick={() => void addKey()}
          >
            {addKeyStatus === "loading" ? "Adding SSH Key..." : "Add SSH Key"}
          </Button>
        ) : (
          <Button
            disabled={!name || nameError || addKeyStatus === "loading"}
            type="submit"
            variant="contained"
            onClick={() => void addKey()}
          >
            {addKeyStatus === "loading" ? "Creating Keypair..." : "Create Keypair"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
