import enhancedFetch from "Utils/enhancedFetch";
import { API_URL } from "Utils/envVariables";
import { fetchDefaultConfigs } from "../config";
import { ErrorKeyJsendResponse, errorKeyJsendResponseDecoder } from "../interfaces";
import {
  AddCardResponse,
  BillingDetailsResponse,
  HasPendingInvoiceResponse,
  PayInvoicePayload,
  addCardResponseDecoder,
  billingDetailsResponseDecoder,
  hasPendingInvoiceResponseDecoder,
} from "./interfaces";

export async function addNewCard(tokenId: string): Promise<AddCardResponse> {
  const response = await enhancedFetch(`${API_URL}/neosaccount/add_card/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify({
      token_id: tokenId,
    }),
  });

  return addCardResponseDecoder.verify(await response.json());
}

export async function hasPendingInvoice(): Promise<HasPendingInvoiceResponse> {
  const response = await enhancedFetch(`${API_URL}/neosaccount/has_pending_invoice/`, {
    ...fetchDefaultConfigs(),
    method: "GET",
  });

  return hasPendingInvoiceResponseDecoder.verify(await response.json());
}

export async function payInvoice(payload: PayInvoicePayload): Promise<ErrorKeyJsendResponse> {
  const response = await enhancedFetch(`${API_URL}/neosaccount/pay_invoice/`, {
    ...fetchDefaultConfigs(),
    method: "POST",
    body: JSON.stringify(payload),
  });

  return errorKeyJsendResponseDecoder.verify(await response.json());
}

export async function getMachineBillingDetails(): Promise<BillingDetailsResponse> {
  const response = await enhancedFetch(`${API_URL}/neosaccount/billing_details/`, {
    ...fetchDefaultConfigs(),
    method: "GET",
  });

  return billingDetailsResponseDecoder.verify(await response.json());
}
