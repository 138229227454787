import {
  Box,
  FormHelperText,
  Link,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import {
  FormikForm,
  FormikSelect,
  FormikSubmitButton,
  MoneyFormat,
  useBackdropContext,
} from "@nc/neoscloud-common-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useHandleError } from "Hooks/useHandleError";
import { BillingDetails, PayInvoicePayload, PendingInvoiceDecoder } from "Services/api/neosaccount/interfaces";
import { getMachineBillingDetails, hasPendingInvoice, payInvoice } from "Services/api/neosaccount/neosaccount";
import { Query } from "Shared/Query/Query";
import { NEOSACCOUNT_URL } from "Utils/envVariables";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { Fragment, useState } from "react";

export function Billing(): JSX.Element {
  const result = useQuery({
    queryKey: [getMachineBillingDetails.name],
    queryFn: async () => {
      const { status, data } = await getMachineBillingDetails();
      if (status !== "success") throw "Error fetching billing details!";

      return data;
    },
  });

  return <Query result={result} onSuccess={(data) => <BillingSection data={data} />} />;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const StyledSectionContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  border: "1px solid rgb(218, 220, 224)",
  borderRadius: "8px",
  padding: "20px",
  mb: 2,
});

interface BilllingSectionProps {
  data: BillingDetails;
}

function BillingSection({ data }: BilllingSectionProps) {
  const {
    pending,
    upcoming: { payment_due, machines, volumes, subtotal, total },
    payment_methods,
  } = data;
  const [pendingInvoices, setPendingInvoices] = useState(pending);
  const hasUpcoming = machines.length > 0 || volumes.length > 0;
  const queryClient = useQueryClient();

  return (
    <Stack spacing={2} component="section">
      <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="space-between">
        <h2>Billing</h2>
        <Link href={`${NEOSACCOUNT_URL}/billing/payment/`}>Manage payment methods</Link>
      </Stack>
      {pendingInvoices.length > 0 && (
        <StyledSectionContainer component="section">
          <h3>Pending invoices</h3>
          <Table sx={{ minWidth: 550, textAlign: "center" }} aria-label="Upcoming invoices">
            <TableHead>
              <TableRow>
                <TableCell>Invoices</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pendingInvoices.map((item) => (
                <PendingInvoiceRow
                  key={item.id}
                  invoice={item}
                  payment_methods={payment_methods}
                  onPayment={(invoiceId) => {
                    const invoices = pendingInvoices.filter((invoice) => invoice.id !== invoiceId);
                    setPendingInvoices(invoices);
                    queryClient.setQueryData<BillingDetails>([getMachineBillingDetails.name], (prev) => {
                      if (!prev) return prev;
                      return {
                        ...prev,
                        pending: invoices,
                      };
                    });
                    void queryClient.setQueryData<boolean>(
                      [hasPendingInvoice.name],
                      invoices.length > 0 ? true : false
                    );
                  }}
                />
              ))}
            </TableBody>
          </Table>
        </StyledSectionContainer>
      )}
      <Fragment>
        <Stack
          component={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            StyledSectionContainer
          }
          spacing={2}
        >
          <h3>Amount due</h3>
          <Typography>This is the amount you owe this month based on your current month-to-date usage.</Typography>
          <Typography sx={{ fontSize: "1.6rem" }}>
            <MoneyFormat amount={total} />
          </Typography>
          {hasUpcoming && (
            <Stack>
              <Typography fontWeight={"bold"}>Payment due:</Typography>
              <Typography>{payment_due}</Typography>
            </Stack>
          )}
        </Stack>
        {hasUpcoming && (
          <StyledSectionContainer component="section">
            <h3>Month-to-date Summary</h3>

            <Table sx={{ minWidth: 550, textAlign: "center" }} aria-label="Upcoming invoices">
              {machines.length > 0 && (
                <Fragment>
                  <TableHead>
                    <TableRow>
                      <TableCell>Machine</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {machines.map((item) => (
                      <TableRow key={item.subscription}>
                        <TableCell>
                          {item.name}{" "}
                          <span style={{ color: "darkred" }}>{item.cancel_at_period_end ? "(canceled)" : ""}</span>
                        </TableCell>
                        <TableCell align="right">
                          <MoneyFormat amount={item.total} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Fragment>
              )}
              {volumes.length > 0 && (
                <Fragment>
                  <TableHead>
                    <TableRow>
                      <TableCell>Volume</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {volumes.map((item) => (
                      <TableRow key={item.subscription}>
                        <TableCell>
                          {item.name}{" "}
                          <span style={{ color: "darkred" }}>{item.cancel_at_period_end ? "(canceled)" : ""}</span>
                        </TableCell>
                        <TableCell align="right">
                          <MoneyFormat amount={item.total} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Fragment>
              )}

              <TableBody>
                <TableRow>
                  <TableCell align="right" sx={{ borderBottom: "none" }}>
                    <strong>Subtotal</strong>
                  </TableCell>
                  <TableCell align="right" sx={{ borderBottom: "none" }}>
                    <MoneyFormat amount={subtotal} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" sx={{ borderBottom: "none" }}>
                    <strong>Total</strong>
                  </TableCell>
                  <TableCell align="right" sx={{ borderBottom: "none" }}>
                    <MoneyFormat amount={total} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </StyledSectionContainer>
        )}
      </Fragment>
    </Stack>
  );
}

interface PendingInvoiceRowProps {
  invoice: PendingInvoiceDecoder;
  payment_methods: BillingDetails["payment_methods"];
  onPayment: (invoiceId: string) => void;
}

function PendingInvoiceRow({ invoice, payment_methods, onPayment }: PendingInvoiceRowProps) {
  const [, setBackdropState] = useBackdropContext();
  const { enqueueSnackbar } = useSnackbar();
  const handleError = useHandleError();
  const [error, setError] = useState<string>("");

  const { mutateAsync: onPay } = useMutation({
    mutationFn: async (payload: PayInvoicePayload) => {
      setError("");
      setBackdropState({
        open: true,
        msg: "Paying invoice...",
      });
      const { status, data } = await payInvoice(payload);

      if (status === "success") {
        enqueueSnackbar(data, {
          variant: "success",
        });
        onPayment(payload.invoiceId);
      } else if (data.errorKey === "stripeCardError") {
        enqueueSnackbar(data.message, {
          variant: "error",
        });
        setError(data.message);
      } else {
        enqueueSnackbar("An error occured while attemting payment", {
          variant: "error",
        });
        console.error(data);
      }
    },
    onError: handleError,
    onSettled: () => {
      setBackdropState({
        open: false,
      });
    },
  });

  return (
    <TableRow key={invoice.subscription}>
      <TableCell>
        <Stack spacing={1}>
          <div>
            <strong>Created: </strong>
            {invoice.created}
          </div>
          <div>
            <strong>Type: </strong>
            {invoice.type}
          </div>
          <div>
            <strong>Name: </strong>
            {invoice.name}
          </div>
          <div>
            <strong>Amount: </strong> <MoneyFormat amount={invoice.total} />
          </div>
          <div>
            <strong>Pay before {invoice.removal_date} to avoid removal </strong>
          </div>
        </Stack>
      </TableCell>
      <TableCell>
        <Formik
          initialValues={{
            paymentMethodId: payment_methods[0]?.id || "",
          }}
          onSubmit={async ({ paymentMethodId }, { setSubmitting }) => {
            await onPay({
              invoiceId: invoice.id,
              paymentMethodId,
            });
            setSubmitting(false);
          }}
        >
          <FormikForm width="100%">
            <Stack spacing={1}>
              {payment_methods.length > 0 ? (
                <Fragment>
                  <FormikSelect
                    name="paymentMethodId"
                    id="paymentMethodId"
                    label="Choose payment method:"
                    error={Boolean(error)}
                  >
                    {payment_methods.map((method) => (
                      <MenuItem key={method.id} value={method.id}>
                        {method.brand} ending in {method.last4}
                      </MenuItem>
                    ))}
                  </FormikSelect>
                  <FormHelperText error={Boolean(error)}>{error}</FormHelperText>
                  <FormikSubmitButton id="pay-button" variant="contained">
                    Pay
                  </FormikSubmitButton>{" "}
                </Fragment>
              ) : (
                <>No payment methods available, please add one so you can pay this invoice.</>
              )}
            </Stack>
          </FormikForm>
        </Formik>
      </TableCell>
    </TableRow>
  );
}
