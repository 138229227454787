import { Circle, MoreHoriz } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useBackdropContext } from "@nc/neoscloud-common-react";
import { useMutation } from "@tanstack/react-query";
import { useHandleError } from "Hooks/useHandleError";
import { DialogState } from "Pages/Dashboard/interfaces";
import { deleteInstance, getUserInstance } from "Services/api/instances/instances";
import { Instance } from "Services/api/instances/interfaces";
import { confirmWrapper } from "Utils/confirmWrapper";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { Dispatch, Fragment, useEffect, useState } from "react";
import { MachineStatus } from "./interfaces";

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const StyledTd = styled("td")({
  verticalAlign: "top",
});

const STATUS_COLOR = Object.freeze({
  ACTIVE: "green",
  PAUSED: "#aaaa1c",
  SHUTOFF: "#930000",
});

interface MachineProps {
  instance: Instance;
  setDialogState: Dispatch<DialogState>;
  onDelete: (instanceId: string) => void;
  onUpdate: (instanceId: string, status: MachineStatus) => Promise<void>;
  onRefetch: (instance: Instance) => void;
}

export function Machine({ instance, setDialogState, onDelete, onUpdate, onRefetch }: MachineProps) {
  const [showIp, setShowIp] = useState(true);
  const [timeoutID, setTimeoutID] = useState<number | undefined>(undefined);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const status = instance.status as keyof typeof STATUS_COLOR;
  const dotColor = instance.status in STATUS_COLOR ? STATUS_COLOR[status] : "transparent";

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (instance.status === "BUILD" && !timeoutID)
      setTimeoutID(
        window.setTimeout(
          () =>
            void (async () => {
              const { data, status } = await getUserInstance(instance.id);
              if (status === "success") onRefetch(data);
              else enqueueSnackbar("Error getting instance", { variant: "error" });
              setTimeoutID(undefined);
            })(),
          60000
        )
      );
    else if (instance.status !== "BUILD" && timeoutID) clearTimeout(timeoutID);

    return () => clearTimeout(timeoutID);
  }, [instance, timeoutID, enqueueSnackbar, onRefetch]);

  return (
    <Fragment>
      <Accordion
        onChange={(_, expanded) => {
          setShowIp(!expanded);
        }}
        data-testid={"instance-container"}
      >
        <AccordionSummary
          expandIcon={
            instance.status !== "BUILD" ? (
              <div>
                <IconButton
                  id="actions-button"
                  data-testid={`actions-${instance.id}`}
                  aria-controls={open ? "actions-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAnchorEl(e.currentTarget);
                  }}
                >
                  <MoreHoriz />
                </IconButton>
                <Menu
                  id="actions-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    setAnchorEl(null);
                  }}
                  MenuListProps={{
                    "aria-labelledby": "actions-button",
                  }}
                >
                  {instance.status !== "ACTIVE" && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        void onUpdate(instance.id, MachineStatus.ACTIVE);
                        setAnchorEl(null);
                      }}
                    >
                      Start
                    </MenuItem>
                  )}
                  {instance.status === "ACTIVE" && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        void onUpdate(instance.id, MachineStatus.SHUTOFF);
                        setAnchorEl(null);
                      }}
                    >
                      Stop
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setDialogState({
                        dialog: "editSecurityGroups",
                        instance: instance,
                      });
                      setAnchorEl(null);
                    }}
                  >
                    Edit Security Groups
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setDialogState({
                        dialog: "addVolume",
                        instance: instance,
                      });
                      setAnchorEl(null);
                    }}
                  >
                    Attach volume
                  </MenuItem>
                  <DeleteMachineItem instance={instance} setAnchorEl={setAnchorEl} onDelete={onDelete} />
                </Menu>
              </div>
            ) : (
              <span>...building</span>
            )
          }
          sx={{
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "none",
            },
          }}
        >
          <Stack
            direction={isSmall ? "column" : "row"}
            alignItems={isSmall ? "flex-start" : "center"}
            gap="5px 15px"
            flexWrap="wrap"
            width="100%"
          >
            <Stack alignItems="center" spacing={1} direction="row">
              {instance.status === "BUILD" && <CircularProgress color="inherit" size={15} />}
              {instance.status !== "BUILD" && <Circle sx={{ color: dotColor, width: "10px" }} />}
              <Typography textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" maxWidth="55vw">
                <strong>{instance.name}</strong>
              </Typography>
            </Stack>
            {showIp && <Typography> {instance.ip} </Typography>}
            <Stack direction="row" alignItems="center" gap="2px 10px" flexWrap="wrap">
              {instance.tags.map((tag) => (
                <Chip key={tag} label={tag} />
              ))}
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ borderTop: "1px solid #bebebe" }}>
          <Stack direction="row" sx={{ p: "15px" }} gap="5px 40px">
            <table style={{ height: "fit-content" }}>
              <tbody>
                <tr>
                  <StyledTd>
                    <strong>Image</strong>
                  </StyledTd>
                  <StyledTd
                    style={{ paddingLeft: "15px" }}
                  >{`${instance.image.distribution} ${instance.image.version}`}</StyledTd>
                </tr>
                <tr>
                  <StyledTd>
                    <strong>Size</strong>
                  </StyledTd>
                  <StyledTd style={{ paddingLeft: "15px" }}>
                    <Stack>
                      <Typography>{instance.plan.cpu}</Typography>
                      <Typography>{instance.plan.memory}</Typography>
                      <Typography>{instance.plan.disk}</Typography>
                      <Typography>{instance.plan.price}</Typography>
                    </Stack>
                  </StyledTd>
                </tr>
                <tr>
                  <StyledTd>
                    <strong>Volumes</strong>
                  </StyledTd>
                  <StyledTd style={{ paddingLeft: "15px" }}>{instance.volumes.length}</StyledTd>
                </tr>
                {isSmall &&
                  instance.networks.map((network) => (
                    <tr key={network.ip}>
                      <StyledTd>
                        <strong>
                          {network.version} {!network.public && "(Private)"}
                        </strong>
                      </StyledTd>
                      <StyledTd style={{ paddingLeft: "15px" }}>{network.ip}</StyledTd>
                    </tr>
                  ))}
              </tbody>
            </table>
            {!isSmall && (
              <table style={{ height: "fit-content" }}>
                <tbody>
                  {instance.networks.map((network) => (
                    <tr key={network.ip}>
                      <StyledTd>
                        <strong>
                          {network.version} {!network.public && "(Private)"}
                        </strong>
                      </StyledTd>
                      <StyledTd style={{ paddingLeft: "15px" }}>{network.ip}</StyledTd>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Fragment>
  );
}

function DeleteMachineItem({
  instance: { id, name },
  setAnchorEl,
  onDelete,
}: {
  instance: Instance;
  setAnchorEl: Dispatch<HTMLElement | null>;
  onDelete: MachineProps["onDelete"];
}) {
  const confirm = useConfirm();
  const [, setBackdropState] = useBackdropContext();
  const { enqueueSnackbar } = useSnackbar();
  const handleError = useHandleError();

  const { mutateAsync: deleteMachine } = useMutation({
    mutationFn: async () => {
      setBackdropState({ open: true, msg: "Deleting machine..." });
      const { data } = await deleteInstance(id);
      onDelete(id);
      enqueueSnackbar(data, { variant: "success" });
    },
    onError: handleError,
    onSettled: () => {
      setBackdropState({ open: false, msg: "" });
    },
  });

  return (
    <MenuItem
      onClick={(e) => {
        e.stopPropagation();
        setAnchorEl(null);
        confirmWrapper(
          confirm({
            title: `Are you sure you want to delete machine: ${name}?`,
            description:
              "If you delete this machine at the end of the month you will still be charged what you owe based on the current month-to-date usage.",
            confirmationText: "Delete",
          }),
          deleteMachine
        );
      }}
      sx={{ color: "darkred" }}
    >
      Delete
    </MenuItem>
  );
}
