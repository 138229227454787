import enhancedFetch from "Utils/enhancedFetch";
import { API_URL } from "Utils/envVariables";
import { fetchDefaultConfigs } from "../config";
import { GetCurrentUserResponse, getCurrentUserResponseDecoder } from "./interfaces";

export async function getCurrentUser(): Promise<GetCurrentUserResponse> {
  const response = await enhancedFetch(`${API_URL}/users/me/`, {
    ...fetchDefaultConfigs(),
    method: "GET",
  });

  return getCurrentUserResponseDecoder.verify(await response.json());
}
