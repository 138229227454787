import { AccountBalance, Dashboard, Key, Security, Storage } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Alert,
  AlertTitle,
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useBackdropContext } from "@nc/neoscloud-common-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useHandleError } from "Hooks/useHandleError";
import { logout } from "Services/api/auth/auth";
import { hasPendingInvoice } from "Services/api/neosaccount/neosaccount";
import { getCurrentUser } from "Services/api/users/users";
import { AddPaymentDialogProvider } from "Shared/AddPaymentDialogContext/AddPaymentDialogContext";
import { Query } from "Shared/Query/Query";
import { isResponseError } from "Utils/enhancedFetch";
import { NEOSACCOUNT_URL } from "Utils/envVariables";
import { useSnackbar } from "notistack";
import { Fragment, MouseEvent, PropsWithChildren, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import logo from "../../../../public/Neos2CloudLogo.png";
import { useDrawerToggle } from "./useDrawerToggle";

const drawerWidth = 240;

export default function MainContainer({ children }: PropsWithChildren) {
  const { mobileOpen, handleDrawerToggle } = useDrawerToggle();
  const navigate = useNavigate();
  const handleError = useHandleError();
  const [, setBackdropState] = useBackdropContext();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [showWarning, setShowWarning] = useState(false);

  const goToLogin = () => navigate("/login");

  const hasPendingInvoicesResult = useQuery({
    queryKey: [hasPendingInvoice.name],
    queryFn: async () => {
      const { data } = await hasPendingInvoice();

      setShowWarning(data);

      return data;
    },
  });

  const result = useQuery({
    queryKey: [getCurrentUser.name],
    queryFn: async () => {
      setBackdropState({
        open: true,
        msg: "Loading...",
      });
      const { status, data } = await getCurrentUser();
      if (status !== "success") {
        goToLogin();
        return null;
      }

      setBackdropState({
        open: false,
      });
      return data;
    },
    onError: (error) => {
      if (isResponseError(error) && error.status !== 403) handleError(error);

      setBackdropState({
        open: false,
      });

      goToLogin();
    },
  });

  const { mutateAsync: onLogout } = useMutation({
    mutationFn: async () => {
      const result = await logout();

      enqueueSnackbar(result.data, {
        variant: "success",
      });

      navigate("../login");
    },
    onError: handleError,
  });

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Query
      result={result}
      OnLoading={() => <></>}
      onError={() => <></>}
      showDefaultError={false}
      onSuccess={(data) => {
        if (!data) return <></>;

        const { avatar } = data;
        return (
          <Box sx={{ display: "flex" }}>
            <Query
              result={hasPendingInvoicesResult}
              OnLoading={() => null}
              onError={() => null}
              showDefaultError={false}
              onSuccess={(hasPendingInvoice) => (
                <Fragment>
                  {showWarning ? (
                    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                      <Alert severity="error" onClose={() => setShowWarning(false)} sx={{ borderRadius: 0 }}>
                        <AlertTitle>Important</AlertTitle>
                        <Stack spacing={1}>
                          You have pending invoices from previous months that need to be paid.
                          <strong>Please pay them as soon as possible to avoid the removal of your items!</strong>
                          <Link component={RouterLink} to="/billing" color="inherit">
                            <strong>GO HERE TO PAY</strong>
                          </Link>
                        </Stack>
                      </Alert>
                    </AppBar>
                  ) : null}
                  <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
                    <Drawer
                      variant="temporary"
                      open={mobileOpen}
                      onClose={handleDrawerToggle}
                      ModalProps={{
                        keepMounted: true,
                      }}
                      sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                          backgroundColor: (theme) => theme.palette.primary.main,
                          color: "#fff",
                          boxSizing: "border-box",
                          width: drawerWidth,
                        },
                      }}
                    >
                      {showWarning && <Box sx={{ height: "165px" }} />}
                      <DrawerContent />
                    </Drawer>
                    <Drawer
                      variant="permanent"
                      sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": {
                          backgroundColor: (theme) => theme.palette.primary.main,
                          color: "#fff",
                          boxSizing: "border-box",
                          width: drawerWidth,
                        },
                      }}
                      open
                    >
                      {showWarning && <Box sx={{ height: "125px" }} />}
                      <DrawerContent />
                    </Drawer>
                  </Box>
                  <Box component="main" sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
                    {showWarning && <Box sx={{ height: "125px" }} />}
                    {showWarning && isSmall && <Box sx={{ height: "40px" }} />}
                    <Toolbar
                      sx={{
                        borderBottom: "1px solid #f1f1f1",
                        boxShadow: "1px 2px 4px 0 rgb(0 0 0 / 3%)",
                        justifyContent: "space-between",
                      }}
                      direction="row"
                      spacing={2}
                      component={Stack}
                    >
                      <div>
                        <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          edge="start"
                          onClick={handleDrawerToggle}
                          sx={{ mr: 2, display: { sm: "none" } }}
                        >
                          <MenuIcon />
                        </IconButton>
                      </div>

                      <Stack direction="row" spacing={2}>
                        <Button
                          component={RouterLink}
                          to="/machines/new"
                          variant="contained"
                          disabled={hasPendingInvoice}
                        >
                          Create
                        </Button>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Box sx={{ flexGrow: 0 }}>
                          <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} aria-label="open user menu">
                              {avatar ? <Avatar src={avatar} /> : <Avatar />}
                            </IconButton>
                          </Tooltip>
                          <Menu
                            sx={{ mt: "45px" }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                          >
                            <MenuItem onClick={() => (window.location.href = `${NEOSACCOUNT_URL}/account/`)}>
                              <Typography textAlign="center" data-testid="account-link">
                                Account
                              </Typography>
                            </MenuItem>
                            <MenuItem onClick={() => void onLogout()}>
                              <Typography textAlign="center">Logout</Typography>
                            </MenuItem>
                          </Menu>
                        </Box>
                      </Stack>
                    </Toolbar>
                    <Box maxWidth="lg" sx={{ pr: 3, pl: 3, mt: 4, mb: 4 }}>
                      <AddPaymentDialogProvider>{children}</AddPaymentDialogProvider>
                    </Box>
                  </Box>
                </Fragment>
              )}
            />
          </Box>
        );
      }}
    />
  );
}

function DrawerContent() {
  return (
    <div>
      <Toolbar sx={{ px: [2] }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Box
            src={logo}
            sx={{
              width: "40px",
              filter: "invert(1)",
            }}
            component="img"
            alt="Neos2Cloud logo"
          />
          <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            Neos2Cloud
          </Typography>
        </Stack>
      </Toolbar>
      <List>
        <NavItem route="/" text="Dashboard" icon={Dashboard} />
        <NavItem route="/volumes" text="Volumes" icon={Storage} />
        <NavItem route="/keys" text="Keys" icon={Key} />
        <NavItem route="/billing" text="Billing" icon={AccountBalance} />
        <NavItem route="/security-groups" text="Security Groups" icon={Security} />
      </List>
    </div>
  );
}

function NavItem({ route, text, icon: Icon }: { route: string; text: string; icon?: React.ElementType | undefined }) {
  return (
    <ListItem disablePadding>
      <ListItemButton component={RouterLink} to={route}>
        <>
          {Icon && <Icon sx={{ marginRight: "6px" }} />}
          <ListItemText primary={text} />
        </>
      </ListItemButton>
    </ListItem>
  );
}
