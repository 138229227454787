import { AddPaymentMethod, useBackdropContext } from "@nc/neoscloud-common-react";
import { addNewCard } from "Services/api/neosaccount/neosaccount";
import { useSnackbar } from "notistack";
import { Dispatch, PropsWithChildren, createContext, useContext, useState } from "react";

interface AddPaymentDialogState {
  open: boolean;
  callback: (() => Promise<void>) | (() => void);
}

const defaultState: AddPaymentDialogState = {
  open: false,
  callback: () => Promise.resolve(),
};

const AddPaymentDialogContext = createContext<[AddPaymentDialogState, Dispatch<AddPaymentDialogState>]>([
  defaultState,
  (() => {}) as Dispatch<AddPaymentDialogState>,
]);

export function useAddPaymentDialogContext(
  submit: AddPaymentDialogState["callback"]
): [AddPaymentDialogState, (open: boolean) => void] {
  const [dialogState, setDialogState] = useContext(AddPaymentDialogContext);

  return [dialogState, (open: boolean) => setDialogState({ open, callback: submit })];
}

export function AddPaymentDialogProvider({ children }: PropsWithChildren) {
  const [dialogState, setDialogState] = useState<AddPaymentDialogState>(defaultState);
  const [, setBackdropState] = useBackdropContext();
  const { open, callback } = dialogState;
  const { enqueueSnackbar } = useSnackbar();
  const setOpenCardDialog = (open: boolean) => setDialogState({ ...dialogState, open });

  return (
    <AddPaymentDialogContext.Provider value={[dialogState, (dialogState) => setDialogState(dialogState)]}>
      {children}
      <AddPaymentMethod
        isOpen={open}
        onClose={() => setDialogState({ ...dialogState, open: false })}
        onAddPaymentMethod={async (token: string) => {
          await addNewCard(token);

          enqueueSnackbar("Payment method added successfully", {
            variant: "success",
          });
          setOpenCardDialog(false);
          await callback();
        }}
        onCreating={() => setBackdropState({ open: true, msg: "Creating payment method..." })}
        onError={(error) => {
          setBackdropState({ open: false, msg: "" });
          enqueueSnackbar(error, {
            variant: "error",
          });
        }}
        onSuccess={() => setBackdropState({ open: false, msg: "" })}
      />
    </AddPaymentDialogContext.Provider>
  );
}
