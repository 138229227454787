import { Box, ButtonBase, Card, CardContent } from "@mui/material";
import { MouseEventHandler } from "react";

export default function SelectCard({
  onClick,
  isSelected,
  topElement,
  bottomElement,
  minHeight,
  dataTestid,
}: {
  onClick: MouseEventHandler<HTMLButtonElement> | undefined;
  isSelected: boolean;
  topElement: JSX.Element;
  bottomElement: JSX.Element;
  minHeight?: number;
  dataTestid?: string;
}) {
  return (
    <ButtonBase sx={{ padding: "8px" }} onClick={onClick} data-testid={dataTestid} data-selected={isSelected}>
      <Card
        sx={{
          minWidth: 170,
          ...(minHeight ? { minHeight } : {}),
          ...(isSelected
            ? { background: "#000", color: "#fff" }
            : {
                "&:hover": {
                  backgroundColor: "#f1f1f1",
                },
                border: "1px solid #dfdfdf",
              }),
        }}
      >
        <CardContent>
          <Box sx={{ pb: "15px" }}>{topElement}</Box>
          <Box sx={{ borderTop: "1px solid #dfdfdf", pt: "15px" }}>{bottomElement}</Box>
        </CardContent>
      </Card>
    </ButtonBase>
  );
}
