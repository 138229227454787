export type DialogState = {
  instance: {
    id: string;
    name: string;
  };
  dialog: "addVolume" | "editSecurityGroups" | "close";
};

export enum MachineStatus {
  ACTIVE = "ACTIVE",
  PAUSED = "PAUSE",
  SHUTOFF = "SHUTOFF",
}
