import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getAppDetails } from "Services/api/instances/instances";
import { CreateInstanceOptions, Distribution } from "Services/api/instances/interfaces";
import { Query } from "Shared/Query/Query";
import { useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { FormData } from "./interfaces";

export default function ChooseImageSection({
  distributions,
  apps,
  onChange,
}: {
  distributions: CreateInstanceOptions["distributions"];
  apps: CreateInstanceOptions["apps"];
  onChange: (image: FormData["image"]) => void;
}) {
  const [index, setIndex] = useState(0);
  const [distro, setDistro] = useState<Distribution>(distributions[index]);
  const [versionIndex, setVersionIndex] = useState(0);
  const [type, setType] = useState<"os" | "app">("os");
  const [appId, setAppId] = useState(0);
  const [appName, setAppName] = useState("");
  const [appDetailId, setAppDetailId] = useState(0);

  return (
    <section>
      <h2>Choose an image</h2>

      <Stack component="section" spacing={2}>
        <section>
          <FormControl>
            <RadioGroup
              row
              name="authentication-group"
              value={type}
              onChange={({ target: { value } }) => {
                if (value === "os" || value === "app") {
                  setType(value);
                  onChange({
                    type: value,
                    os: { name: distro.name, version: distro.versions[versionIndex] },
                    app: { id: appId, name: appName },
                  });
                }
              }}
            >
              <FormControlLabel value="os" control={<Radio />} label="OS" />
              <FormControlLabel value="app" control={<Radio />} label="Application" />
            </RadioGroup>
          </FormControl>
        </section>

        {type === "os" ? (
          <section>
            <FormControl sx={{ m: 1, minWidth: 240 }}>
              <InputLabel id="distribution-label">Distribution</InputLabel>
              <Select
                labelId="distribution-label"
                id="distribution"
                label="Distribution"
                onChange={({ target: { value } }) => {
                  value = Number(value);
                  const distro = distributions[value];
                  const distroVersion = distro.versions[0];
                  setIndex(value);
                  setDistro(distro);
                  setVersionIndex(0);
                  onChange({
                    type: "os",
                    os: { name: distro.name, version: distroVersion },
                    app: { id: appId, name: appName },
                  });
                }}
                value={index}
              >
                {distributions.map((distro, index) => (
                  <MenuItem key={distro.name} value={index}>
                    {distro.display}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 240 }}>
              <InputLabel id="distribution-version-label">Version</InputLabel>
              <Select
                labelId="distribution-version-label"
                id="distribution-version"
                label="Version"
                onChange={({ target: { value } }) => {
                  value = Number(value);
                  setVersionIndex(value);
                  onChange({
                    type: "os",
                    os: { name: distro.name, version: distro.versions[value] },
                    app: { id: appId, name: appName },
                  });
                }}
                value={versionIndex}
              >
                {distro.versions.map((version, index) => (
                  <MenuItem key={version.id} value={index}>
                    {version.display}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </section>
        ) : (
          <Grid container spacing={2}>
            {apps.map((app) => (
              <Grid item sm={4} key={app.id}>
                <Stack
                  direction="row"
                  sx={{
                    border: "1px solid rgba(33,33,33,0.5)",
                    borderRadius: "3px",
                    width: "100%",
                    ...(appId === app.id ? { background: "#000" } : {}),
                  }}
                  justifyContent="space-between"
                >
                  <Button
                    onClick={() => {
                      setAppId(app.id);
                      setAppName(app.name);
                      onChange({
                        type: "app",
                        os: { name: distro.name, version: distro.versions[versionIndex] },
                        app: { id: app.id, name: app.name },
                      });
                    }}
                    sx={{ color: appId === app.id ? "#fff" : "#000" }}
                    startIcon={
                      <Box
                        component="img"
                        src={app.logo}
                        sx={{ maxWidth: "32px", maxHeight: "32px", overflow: "hidden" }}
                        alt={`${app.name} ${app.version} on ${app.os} ${app.os_version}`}
                      />
                    }
                  >
                    <Box
                      sx={{ overflow: "hidden", textOverflow: "ellipsis", textWrap: "nowrap" }}
                    >{`${app.name} ${app.version} on ${app.os} ${app.os_version}`}</Box>
                  </Button>
                  <Button
                    onClick={() => {
                      setAppDetailId(app.id);
                    }}
                    sx={{ color: appId === app.id ? "#fff" : "#000" }}
                  >
                    Details
                  </Button>
                </Stack>
              </Grid>
            ))}
          </Grid>
        )}
      </Stack>
      {appDetailId > 0 && <ApplicationDetailsDialog open onClose={() => setAppDetailId(0)} app={appDetailId} />}
    </section>
  );
}

interface ApplicationDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  app: number;
}

export function ApplicationDetailsDialog({ open, onClose, app }: ApplicationDetailsDialogProps) {
  const result = useQuery({
    queryKey: [getAppDetails.name, app],
    queryFn: async () => {
      const { data } = await getAppDetails(app);

      return data;
    },
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Application Details
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Query
          result={result}
          onSuccess={(app) => (
            <Stack spacing={2}>
              <Box
                component="img"
                src={app.logo}
                sx={{ maxWidth: "86px", maxHeight: "86px", overflow: "hidden" }}
                alt={app.name}
              />
              <Typography component="h1" variant="h5">
                {app.name}
              </Typography>
              <Stack direction="row" spacing={1}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Version
                </Typography>
                <Typography variant="body1">{app.version}</Typography>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  OS
                </Typography>
                <Typography variant="body1">{`${app.os} ${app.os_version}`}</Typography>
              </Stack>
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {app.categories.map((category) => (
                  <Chip key={category.id} label={category.name} />
                ))}
              </Stack>
              <Divider />
              <ReactMarkdown>{app.details}</ReactMarkdown>
            </Stack>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
