import { Box, Stack, TextField, Typography } from "@mui/material";
import PriceInfo from "Pages/CreateMachine/PriceInfo";
import SelectCard from "Pages/CreateMachine/SelectCard";
import { Dispatch, useEffect, useState } from "react";
import { CreateInstanceOptions } from "Services/api/instances/interfaces";
import { SelectVolumeState } from "./interfaces";

export interface SelectVolumeProps {
  storages: CreateInstanceOptions["storages"];
  storagePricePerGB: number;
  storageMaxHours: number;
  onChange: (data: SelectVolumeState) => void;
}

export function SelectVolume({ storages, storagePricePerGB, storageMaxHours, onChange }: SelectVolumeProps) {
  const customStorageId = "custom";

  const [selectedStorage, setSelectedStorage] = useState<string>(
    storages.length > 0 ? storages[0].id.toString() : "custom"
  );
  const [customStorageSize, setCustomStorageSize] = useState<number | string>("");
  const [errorInCustomStorage, setErrorInCustomStorage] = useState(false);

  useEffect(() => {
    if (storages.length > 0) {
      onChange({
        size: storages[0].size,
        error: false,
      });
    }
  }, []);

  return (
    <Stack spacing={2}>
      <section>
        <CustomStorage
          {...{
            selectedStorage,
            setSelectedStorage,
            storagePricePerGB,
            storageMaxHours,
            customStorageSize,
            setCustomStorageSize,
            errorInCustomStorage,
            customStorageId,
            setErrorInCustomStorage,
            onChange,
          }}
        />
        {storages.map((storage) => (
          <SelectCard
            key={storage.id}
            dataTestid={`storage-${storage.id}`}
            onClick={() => {
              setSelectedStorage(storage.id.toString());
              onChange({
                size: storage.size,
                error: false,
              });
            }}
            isSelected={selectedStorage === storage.id.toString()}
            topElement={<PriceInfo costPerMonth={storage.costPerMonth} costPerHour={storage.costPerHour}></PriceInfo>}
            bottomElement={<div>{storage.name}</div>}
            minHeight={170}
          ></SelectCard>
        ))}
      </section>
      <Typography sx={{ fontStyle: "italic", textAlign: "justify" }}>
        Volumes cost $0.10 GiB per month and start from 1 GiB. Charges accrue hourly for as long as the volume exists.
        You are charged for volumes whether or not they are attached to an instance.
      </Typography>
    </Stack>
  );
}

interface CustomStorageProps {
  selectedStorage: string;
  setSelectedStorage: Dispatch<string>;
  storagePricePerGB: number;
  storageMaxHours: number;
  customStorageSize: string | number;
  setCustomStorageSize: Dispatch<string | number>;
  errorInCustomStorage: boolean;
  customStorageId: string;
  setErrorInCustomStorage: Dispatch<boolean>;
  onChange: (data: SelectVolumeState) => void;
}

function CustomStorage({
  selectedStorage,
  setSelectedStorage,
  storagePricePerGB,
  storageMaxHours,
  customStorageSize,
  setCustomStorageSize,
  errorInCustomStorage,
  customStorageId,
  setErrorInCustomStorage,
  onChange,
}: CustomStorageProps) {
  const customValueId = customStorageId;
  const isCustomSelected = selectedStorage === customValueId;
  const [costPerMonth, setCostPerMonth] = useState("0");
  const [costPerHour, setCostPerHour] = useState("0");

  useEffect(() => {
    setCostPerMonth(calculateMonthlyPrice(storagePricePerGB, Number(customStorageSize)));
    setCostPerHour(calculateHourlyPrice(storagePricePerGB, Number(customStorageSize), storageMaxHours));
  }, [customStorageSize, storagePricePerGB, storageMaxHours]);

  return (
    <SelectCard
      onClick={() => {
        setSelectedStorage(customValueId);
        onChange({
          size: Number(customStorageSize),
          error: isErrorInCustomStorageSize(customStorageSize),
        });
      }}
      dataTestid={"custom-storage"}
      isSelected={isCustomSelected}
      topElement={<PriceInfo costPerMonth={costPerMonth} costPerHour={costPerHour}></PriceInfo>}
      minHeight={170}
      bottomElement={
        <Box sx={{ maxWidth: 138 }}>
          <TextField
            sx={{
              ...(isCustomSelected && {
                "& label": {
                  color: "#fff",
                },
                "& label.Mui-focused": {
                  color: "#fff",
                },
                "& input": {
                  color: "#fff",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#fff",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#fff",
                },
              }),
            }}
            label={errorInCustomStorage ? "Not a valid size" : "Enter size in GB"}
            type="number"
            size="small"
            variant="standard"
            value={customStorageSize}
            onChange={({ target: { value } }) => {
              if (value === "") {
                setCustomStorageSize("");
                onChange({ size: 0, error: true });
              } else {
                setCustomStorageSize(Number(value));
                onChange({
                  size: Number(value),
                  error: isErrorInCustomStorageSize(value),
                });
              }
            }}
            onBlur={({ target: { value } }) => setErrorInCustomStorage(isErrorInCustomStorageSize(value))}
            required={isCustomSelected}
            error={isCustomSelected && errorInCustomStorage}
            placeholder="Enter size in GB"
          />
        </Box>
      }
    ></SelectCard>
  );
}

function isErrorInCustomStorageSize(size: number | string) {
  size = Number(size);
  if (Number.isNaN(size)) return true;
  if (size < 1 || size > 16384) return true;
  if (!Number.isInteger(size)) return true;
  return false;
}

function calculateMonthlyPrice(pricePerGB: number, amount: number) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 2,
  });
  return () => formatter.format(pricePerGB * amount);
}

function calculateHourlyPrice(pricePerGB: number, amount: number, maxHours: number) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 3,
  });
  return () => formatter.format((pricePerGB * amount) / maxHours);
}
