import { Button, Stack, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useHandleError } from "Hooks/useHandleError";
import { getCreateInstanceOptions } from "Services/api/instances/instances";
import { CreateInstanceOptions } from "Services/api/instances/interfaces";
import { ListKey } from "Services/api/keypairs/interfaces";
import { deleteKeyPair, getUserKeys } from "Services/api/keypairs/keypairs";
import { AddSshKeyDialog } from "Shared/AddSshKeyDialog/AddSshKeyDialog";
import { Query } from "Shared/Query/Query";
import { confirmWrapper } from "Utils/confirmWrapper";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { useState } from "react";

export function Keys(): JSX.Element {
  const result = useQuery({
    queryKey: [getUserKeys.name],
    queryFn: async () => {
      const { status, data } = await getUserKeys();
      if (status !== "success") throw "Error fetching user keys!";

      return data;
    },
  });

  return <Query result={result} onSuccess={(keys) => <KeysSection keys={keys} />} />;
}

interface KeysSectionProps {
  keys: ListKey[];
}

function KeysSection({ keys }: KeysSectionProps) {
  const [sshKeys, setSshKeys] = useState(keys);
  const [openNewSshKeyDialog, setOpenNewSshKeyDialog] = useState(false);
  const queryClient = useQueryClient();

  return (
    <section>
      <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="space-between">
        <h2>SSH Keys</h2>
        <Button variant="contained" onClick={() => setOpenNewSshKeyDialog(true)}>
          Add SSH Key
        </Button>
      </Stack>

      {sshKeys.length > 0 ? (
        <Table sx={{ minWidth: 550, textAlign: "center" }} aria-label="Keys">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Fingerprint</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {sshKeys.map((key) => (
              <KeyRow
                key={key.id}
                sshKey={key}
                onDelete={(keyId) => {
                  const keys = sshKeys.filter((sshKey) => sshKey.id !== keyId);
                  setSshKeys(keys);
                  queryClient.setQueryData<ListKey[]>([getUserKeys.name], keys);

                  queryClient.setQueryData<CreateInstanceOptions>([getCreateInstanceOptions.name], (old) => {
                    if (!old) return old;
                    return {
                      ...old,
                      keys: old.keys.filter((key) => key.id !== keyId),
                    };
                  });
                }}
              />
            ))}
          </TableBody>
        </Table>
      ) : (
        <div>You don&apos;t have any keys...</div>
      )}
      <AddSshKeyDialog
        open={openNewSshKeyDialog}
        handleClose={() => setOpenNewSshKeyDialog(false)}
        onKeyCreation={(keyData) => {
          const keys = [...sshKeys, keyData];
          setSshKeys(keys);
          queryClient.setQueryData<ListKey[]>([getUserKeys.name], keys);
          queryClient.setQueryData<CreateInstanceOptions>([getCreateInstanceOptions.name], (old) => {
            if (!old) return old;
            return {
              ...old,
              keys: [...old.keys, { name: keyData.name, id: keyData.id }],
            };
          });
        }}
      />
    </section>
  );
}

interface KeyRowProps {
  sshKey: ListKey;
  onDelete: (keyId: string) => void;
}

function KeyRow({ sshKey, onDelete }: KeyRowProps) {
  const { enqueueSnackbar } = useSnackbar();
  const handleError = useHandleError();
  const confirm = useConfirm();

  const { mutateAsync: deleteKey } = useMutation({
    mutationFn: async () => {
      const { data } = await deleteKeyPair(sshKey.id);
      enqueueSnackbar(data, { variant: "success" });
      onDelete(sshKey.id);
    },
    onError: handleError,
  });

  return (
    <TableRow>
      <TableCell>{sshKey.name}</TableCell>
      <TableCell>{sshKey.fingerprint}</TableCell>
      <TableCell>
        <Button
          id="delete-button"
          color="error"
          variant="contained"
          onClick={() => {
            confirmWrapper(
              confirm({
                title: `Are you sure you want to delete key: ${sshKey.name}?`,
                description:
                  "If you delete this machine at the end of the month you will still be charged what you owe based on the current month-to-date usage.",
                confirmationText: "Delete key",
              }),
              deleteKey
            );
          }}
        >
          Delete
        </Button>
      </TableCell>
    </TableRow>
  );
}
