import { Box } from "@mui/material";

export default function PriceInfo({
  costPerMonth,
  costPerHour,
}: {
  costPerMonth: string | number;
  costPerHour: string | number;
}) {
  return (
    <div>
      <div>
        <Box component="span" sx={{ fontSize: "14px" }}>
          $
        </Box>
        <Box component="span" sx={{ fontSize: "26px", fontWeight: "bold" }}>
          {costPerMonth}
        </Box>
        <Box component="span" sx={{ fontSize: "16px" }}>
          /mo
        </Box>
      </div>
      <div>
        <Box component="span" sx={{ fontSize: "14px" }}>
          ${costPerHour}
        </Box>
        <Box component="span" sx={{ fontSize: "12px" }}>
          /hour
        </Box>
      </div>
    </div>
  );
}
