import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FormikForm, FormikSubmitButton, FormikTextField, useBackdropContext } from "@nc/neoscloud-common-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useHandleError } from "Hooks/useHandleError";
import { getInstanceSecurityGroupOptions } from "Services/api/instances/instances";
import { ListSecurityGroup, SecurityGroup } from "Services/api/securityGroups/interfaces";
import {
  createSecurityGroup,
  deleteSecurityGroup,
  getSecurityGroup,
  getUserSecurityGroups,
  updateSecurityGroup,
} from "Services/api/securityGroups/securityGroups";
import { Query } from "Shared/Query/Query";
import { confirmWrapper } from "Utils/confirmWrapper";
import { Formik } from "formik";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { securityGroupSchema } from "./validations";
import { set } from "ramda";

export function SecurityGroups() {
  const result = useQuery({
    queryKey: [getUserSecurityGroups.name],
    queryFn: async () => {
      const { status, data } = await getUserSecurityGroups();
      if (status !== "success") throw "Error fetching security groups!";

      return data;
    },
  });

  return (
    <Query result={result} onSuccess={(securityGroups) => <SecurityGroupsSection securityGroups={securityGroups} />} />
  );
}

interface SecurityGroupsSectionProps {
  securityGroups: ListSecurityGroup[];
}

function SecurityGroupsSection({ securityGroups }: SecurityGroupsSectionProps) {
  const [securityGroupDialogState, setSecurityGroupDialogState] = useState<SecurityGroupDialogProps["state"]>({
    open: false,
    id: null,
  });
  const [, setBackdropState] = useBackdropContext();
  const { enqueueSnackbar } = useSnackbar();
  const handleError = useHandleError();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutateAsync: deleteGroup } = useMutation({
    mutationFn: async (id: string) => {
      setBackdropState({ open: true, msg: "Deleting security group..." });
      const { status, data } = await deleteSecurityGroup(id);

      if (status !== "success") {
        if (data.errorKey && data.errorKey === "inUse") {
          enqueueSnackbar(data.message, { variant: "error" });
          return;
        }

        throw data;
      }

      enqueueSnackbar(data, { variant: "success" });
      queryClient.setQueryData<ListSecurityGroup[]>(
        [getUserSecurityGroups.name],
        securityGroups.filter((group) => group.id !== id)
      );
      void queryClient.invalidateQueries({ queryKey: [getInstanceSecurityGroupOptions.name] });
    },
    onError: (error) => {
      handleError(error);
    },
    onSettled: () => {
      setBackdropState({ open: false, msg: "" });
    },
  });

  return (
    <section>
      <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="space-between">
        <h2>Security Groups</h2>
        <Button variant="contained" onClick={() => setSecurityGroupDialogState({ open: true, id: null })}>
          Add Security Group
        </Button>
      </Stack>

      {securityGroups.length > 0 ? (
        <Table sx={{ minWidth: 550, textAlign: "center" }} aria-label="Security groups">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {securityGroups.map((securityGroup) => (
              <SecurityGroup
                key={securityGroup.id}
                securityGroup={securityGroup}
                onUpdate={(id) => setSecurityGroupDialogState({ open: true, id })}
                onDelete={() => void deleteGroup(securityGroup.id)}
              />
            ))}
          </TableBody>
        </Table>
      ) : (
        <div>You don&apos;t have any security groups...</div>
      )}
      <SecurityGroupDialog
        state={securityGroupDialogState}
        handleClose={() => setSecurityGroupDialogState({ open: false, id: null })}
        onCreate={(securityGroup) => {
          securityGroups.push(securityGroup);
          queryClient.setQueryData<ListSecurityGroup[]>([getUserSecurityGroups.name], securityGroups);
          queryClient.setQueryData<SecurityGroup>([getSecurityGroup.name, securityGroup.id], securityGroup);
          void queryClient.invalidateQueries({ queryKey: [getInstanceSecurityGroupOptions.name] });

          const query = new URLSearchParams({ add: "true" }).toString();
          navigate(`/security-groups/${securityGroup.id}/?${query}`);
        }}
        onUpdate={(securityGroup) => {
          const index = securityGroups.findIndex((group) => group.id === securityGroup.id);
          securityGroups[index] = securityGroup;
          queryClient.setQueryData<ListSecurityGroup[]>([getUserSecurityGroups.name], securityGroups);
          queryClient.setQueryData<SecurityGroup>([getSecurityGroup.name, securityGroup.id], securityGroup);
          setSecurityGroupDialogState({ open: false, id: null });
        }}
      />
    </section>
  );
}

interface SecurityGroupProps {
  securityGroup: ListSecurityGroup;
  onUpdate: (id: string) => void;
  onDelete: () => void;
}

function SecurityGroup({ securityGroup: { id, name, description }, onUpdate, onDelete }: SecurityGroupProps) {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell>
        <Button
          id="actions-button"
          data-testid={`actions-${id}`}
          aria-controls={open ? "actions-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(e) => {
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
          }}
        >
          Actions
        </Button>
        <Menu
          id="actions-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={(e: React.MouseEvent) => {
            e.stopPropagation();
            setAnchorEl(null);
          }}
          MenuListProps={{
            "aria-labelledby": "actions-button",
          }}
        >
          <MenuItem
            onClick={() => {
              navigate(`/security-groups/${id}/`);
            }}
          >
            Manage rules
          </MenuItem>
          <MenuItem
            onClick={() => {
              onUpdate(id);
              setAnchorEl(null);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              confirmWrapper(
                confirm({
                  title: "Delete security group",
                  description: "Are you sure you want to remove this security group?",
                  confirmationText: "Delete",
                }),
                onDelete
              );
            }}
            sx={{ color: "darkred" }}
          >
            Delete
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
}

interface SecurityGroupDialogProps {
  state: {
    open: boolean;
    id: string | null;
  };
  handleClose: () => void;
  onCreate: (securityGroup: SecurityGroup) => void;
  onUpdate: (securityGroup: SecurityGroup) => void;
}

function SecurityGroupDialog({
  state: { open, id },
  handleClose = () => {},
  onCreate,
  onUpdate,
}: SecurityGroupDialogProps) {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>{id ? "Edit" : "Create"} Security Group</DialogTitle>
      <Formik
        initialValues={{
          name: "",
          description: "",
        }}
        validationSchema={securityGroupSchema}
        onSubmit={async (inputs, { setSubmitting }) => {
          try {
            const { status, data } = id
              ? await updateSecurityGroup({ id, ...inputs })
              : await createSecurityGroup(inputs);
            if (status !== "success") throw data;
            enqueueSnackbar(`Security group ${id ? "updated" : "created"} successfully!`, { variant: "success" });
            setSubmitting(false);
            id ? onUpdate(data) : onCreate(data);
          } catch (error) {
            enqueueSnackbar(`Error ${id ? "updating" : "creating"}  security group!`, { variant: "error" });
            setSubmitting(false);
          }
        }}
      >
        <FormikForm width="100%">
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Stack spacing={2}>
                  <FormikTextField
                    name="name"
                    id="name"
                    hiddenLabel
                    placeholder="Name"
                    variant="outlined"
                    fullWidth
                    required
                  />
                  <FormikTextField
                    name="description"
                    id="description"
                    hiddenLabel
                    placeholder="Description"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                  />
                </Stack>
              </Grid>
              <Grid item sm={6}>
                <h3>Description:</h3>
                <Typography>
                  Security groups are sets of IP filter rules that are applied to network interfaces of a VM. After the
                  security group is created, you can add rules to the security group.
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <FormikSubmitButton variant="contained" submittingText={id ? "Updating..." : "Creating..."}>
              {id ? "Edit" : "Create"}
            </FormikSubmitButton>
          </DialogActions>
        </FormikForm>
      </Formik>
    </Dialog>
  );
}
