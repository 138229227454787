import { UseQueryResult } from "@tanstack/react-query";
import { useHandleError } from "Hooks/useHandleError";
import { GENERIC_ERROR_MESSAGE } from "Utils/constants";
import { useEffect } from "react";

type QueryProps<T, E> = {
  result: UseQueryResult<T, E>;
  onSuccess: (value: T) => JSX.Element | null;
  OnLoading?: () => JSX.Element | null;
  onError?: (error: E) => JSX.Element | null;
  showDefaultError?: boolean;
};

export function Query<T, E>({
  result,
  onSuccess,
  OnLoading = () => <div>Loading...</div>,
  onError = () => <div>{GENERIC_ERROR_MESSAGE}</div>,
  showDefaultError = true,
}: QueryProps<T, E>) {
  const { isError, isLoading, isFetching, data, error } = result;
  const handleError = useHandleError();

  useEffect(() => {
    if (result.isError && showDefaultError) handleError(result.error);
  }, [result, handleError, showDefaultError]);

  if (isError) return onError(error);
  if (isLoading || isFetching) return OnLoading();
  return onSuccess(data);
}
