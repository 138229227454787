import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useBackdropContext } from "@nc/neoscloud-common-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useHandleError } from "Hooks/useHandleError";
import { getInstanceSecurityGroupOptions, updateInstanceSecurityGroups } from "Services/api/instances/instances";
import { SecurityGroupOptions } from "Services/api/instances/interfaces";
import { Query } from "Shared/Query/Query";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

interface EditSecurityGroupsDialogProps {
  instanceId: string;
  onClose: () => void;
}

export function EditSecurityGroupsDialog({ instanceId, onClose }: EditSecurityGroupsDialogProps) {
  const handleError = useHandleError();
  const [selections, setSelections] = useState<SecurityGroupOptions>({
    available: [],
    assigned: [],
  });
  const [, setBackdropState] = useBackdropContext();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const result = useQuery({
    queryKey: [getInstanceSecurityGroupOptions.name, instanceId],
    queryFn: async () => {
      const { data } = await getInstanceSecurityGroupOptions(instanceId);

      return data;
    },
    onError: handleError,
  });

  const { mutateAsync } = useMutation({
    mutationFn: async () => {
      setBackdropState({ open: true, msg: "Updating machine security groups..." });
      const { status, data } = await updateInstanceSecurityGroups(
        instanceId,
        selections.assigned.map((group) => group.id)
      );

      if (status !== "success") throw new Error(data);

      queryClient.setQueryData<SecurityGroupOptions>([getInstanceSecurityGroupOptions.name, instanceId], selections);
      enqueueSnackbar(data, { variant: "success" });
      onClose();
    },
    onError: handleError,
    onSettled: () => {
      setBackdropState({ open: false, msg: "" });
    },
  });

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Edit Security Groups</DialogTitle>
      <DialogContent>
        <Query
          result={result}
          onSuccess={(data) => (
            <SecurityGroupSelector options={data} onSelectionsChange={(selections) => setSelections(selections)} />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => void mutateAsync()} disabled={result.isLoading || result.isFetching}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function SecurityGroupSelector({
  options,
  onSelectionsChange,
}: {
  options: SecurityGroupOptions;
  onSelectionsChange: (selections: SecurityGroupOptions) => void;
}) {
  const [selections, setSelections] = useState<SecurityGroupOptions>({
    available: options.available,
    assigned: options.assigned,
  });
  const { available, assigned } = selections;

  useEffect(() => {
    onSelectionsChange(selections);
  }, [selections, onSelectionsChange]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <DialogContentText>Select the security groups you want to attach to this instance.</DialogContentText>
      </Grid>
      <Grid item sm={6}>
        <Stack spacing={2} sx={{ height: "100%" }}>
          <Typography>Available:</Typography>
          <Stack
            direction="row"
            alignItems="center"
            gap="5px"
            flexWrap="wrap"
            sx={{ border: "1px solid lightgrey", p: "15px", height: "100%" }}
          >
            {available.map((group) => (
              <Chip
                key={group.id}
                label={group.name}
                onClick={() =>
                  setSelections((prev) => ({
                    available: prev.available.filter((g) => g.id !== group.id),
                    assigned: [...prev.assigned, group],
                  }))
                }
              />
            ))}
          </Stack>
        </Stack>
      </Grid>
      <Grid item sm={6}>
        <Stack spacing={2} sx={{ height: "100%" }}>
          <Typography>Selected:</Typography>
          <Stack
            direction="row"
            alignItems="center"
            gap="5px"
            flexWrap="wrap"
            sx={{ border: "1px solid lightgrey", p: "15px", height: "100%" }}
          >
            {assigned.map((group) => (
              <Chip
                key={group.id}
                label={group.name}
                onDelete={() =>
                  setSelections((prev) => ({
                    available: [...prev.available, group],
                    assigned: prev.assigned.filter((g) => g.id !== group.id),
                  }))
                }
              />
            ))}
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}
