import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  FormikForm,
  FormikSelect,
  FormikSubmitButton,
  FormikTextField,
  useBackdropContext,
} from "@nc/neoscloud-common-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useHandleError } from "Hooks/useHandleError";
import { useInvalidateQueries } from "Hooks/useInvalidateQueries";
import { AttachVolumeRequest, CreateVolumeRequest, Volume, VolumeOptions } from "Services/api/volumes/interfaces";
import {
  attachVolume,
  createVolume,
  getAvailableUserVolumes,
  getCreateVolumeOptions,
} from "Services/api/volumes/volumes";
import { useAddPaymentDialogContext } from "Shared/AddPaymentDialogContext/AddPaymentDialogContext";
import { Query } from "Shared/Query/Query";
import { SelectVolume } from "Shared/SelectVolume/SelectVolume";
import { SelectVolumeState } from "Shared/SelectVolume/interfaces";
import { Formik, useField } from "formik";
import { useSnackbar } from "notistack";
import { Fragment, PropsWithChildren, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

export interface AddVolumeDialogProps {
  open: boolean;
  handleClose: () => void;
  onAdd: (volume: Volume | null) => void;
  onAttach?: () => void;
  instanceId?: string;
}

const volumeSchema = Yup.object({
  size: Yup.number()
    .required("Volume size is required")
    .integer("Volume size must be an integer number")
    .positive("Volume size must be greater than zero"),
  machine: Yup.string(),
  name: Yup.string().required("The machine name is required"),
  description: Yup.string(),
});

const attachVolumeSchema = Yup.object({
  instance: Yup.string().required(),
  volume: Yup.string().required(),
});

export function AddVolumeDialog({ open, handleClose, onAdd, onAttach, instanceId }: AddVolumeDialogProps) {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle component={"div"} sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Typography variant="h6">{instanceId ? "Attach volume" : "Add a volume"}</Typography>
        {instanceId && (
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="Add volume tabs" variant="fullWidth">
              <Tab label="Create new" />
              <Tab label="Attach existing" />
            </Tabs>
          </Box>
        )}
      </DialogTitle>
      <TabPanel value={value} index={0}>
        <FormikDialogAddVolume onAdd={onAdd} handleClose={handleClose} instanceId={instanceId} />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <FormikDialogAttachVolume handleClose={handleClose} instanceId={instanceId} onAttach={onAttach} />
      </TabPanel>
    </Dialog>
  );
}

interface TabPanelProps {
  index: number;
  value: number;
}

function TabPanel(props: PropsWithChildren<TabPanelProps>) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function FormikDialogAddVolume({
  onAdd,
  handleClose,
  instanceId,
}: Pick<AddVolumeDialogProps, "onAdd" | "handleClose" | "instanceId">) {
  const { enqueueSnackbar } = useSnackbar();
  const handleError = useHandleError();
  const [, setBackdropState] = useBackdropContext();
  const invalidateQueries = useInvalidateQueries();
  const [, setAddPaymentDialogState] = useAddPaymentDialogContext(() => Promise.resolve());
  const navigate = useNavigate();

  const result = useQuery({
    queryKey: [getCreateVolumeOptions.name],
    queryFn: async () => {
      const { status, data } = await getCreateVolumeOptions();

      if (status !== "success") throw "Error getting options";

      return data;
    },
  });

  const { mutateAsync: executeCreateVolume } = useMutation({
    mutationFn: async (payload: CreateVolumeRequest) => {
      setBackdropState({
        open: true,
        msg: "Creating volume",
      });
      const { status, data } = await createVolume(payload);

      setBackdropState({
        open: false,
        msg: "",
      });

      if (status !== "success") {
        if (data.errorKey === "userNoPaymentMethod") {
          enqueueSnackbar(data.message, { variant: "warning" });
          setAddPaymentDialogState(true);
        } else if (data.errorKey === "pendingInvoice") {
          enqueueSnackbar(data.message, { variant: "error" });
          navigate("/billing");
        } else {
          enqueueSnackbar(data.message, { variant: "error" });
          console.error(data.message);
        }
        return null;
      }

      return data;
    },
    onError: handleError,
    onSettled: () => {
      setBackdropState({
        open: false,
        msg: "",
      });
    },
  });

  return (
    <Formik
      initialValues={{
        size: 0,
        machine: instanceId || "",
        name: "",
        description: "",
      }}
      validationSchema={volumeSchema}
      onSubmit={async ({ size, machine, name, description }, { setSubmitting }) => {
        const volume = await executeCreateVolume({
          name,
          description,
          size,
          instance_id: machine,
        });
        if (!volume) return;
        invalidateQueries("add-volume");

        enqueueSnackbar("Volume created successfully", { variant: "success" });
        setSubmitting(false);
        onAdd(volume);
      }}
      validateOnBlur
    >
      <FormikForm width="100%">
        <DialogContent>
          <Query
            result={result}
            onSuccess={(volumeOptions) => <VolumeForm volumeOptions={volumeOptions} instanceId={instanceId} />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <FormikSubmitButton variant="contained" type="submit">
            Add
          </FormikSubmitButton>
        </DialogActions>
      </FormikForm>
    </Formik>
  );
}

interface VolumeFormProps {
  volumeOptions: VolumeOptions;
  instanceId: AddVolumeDialogProps["instanceId"];
}

function VolumeForm({
  volumeOptions: { storages, storageMaxHours, storagePricePerGB, instances },
  instanceId,
}: VolumeFormProps) {
  const [, { error }, { setValue }] = useField<SelectVolumeState["size"]>("size");
  const [storageError, setStorageError] = useState<SelectVolumeState["error"]>(false);

  return (
    <Fragment>
      <Stack>
        <h5>SELECT VOLUME SIZE</h5>
        <SelectVolume
          storages={storages}
          storageMaxHours={storageMaxHours}
          storagePricePerGB={storagePricePerGB}
          onChange={({ size, error }) => {
            setValue(size);
            setStorageError(error);
          }}
        />
        {storageError && <FormHelperText error> Invalid volume size</FormHelperText>}
        {error && <FormHelperText error> {error}</FormHelperText>}
      </Stack>
      <Grid container spacing={2}>
        {!instanceId && (
          <Grid item sm={6}>
            <h5>SELECT MACHINE TO ATTACH TO</h5>
            <FormikSelect id="machine" name="machine" disabled={Boolean(instanceId)}>
              <MenuItem value="">
                <em>Clear machine...</em>
              </MenuItem>
              {instances.map((instance) => (
                <MenuItem key={instance.reference} value={instance.reference}>
                  {instance.name}
                </MenuItem>
              ))}
            </FormikSelect>
          </Grid>
        )}

        <Grid item sm={6}>
          <h5>NAME VOLUME</h5>
          <FormikTextField
            name="name"
            id="name"
            hiddenLabel
            placeholder="Enter volume name"
            variant="outlined"
            fullWidth
            required
            inputProps={{ "data-testid": "name" }}
          />
        </Grid>
      </Grid>
      <Stack>
        <h5>DESCRIPTION</h5>
        <FormikTextField
          name="description"
          id="description"
          multiline
          hiddenLabel
          placeholder="Enter volume description"
          variant="outlined"
          fullWidth
        />
      </Stack>
    </Fragment>
  );
}

function FormikDialogAttachVolume({
  handleClose,
  instanceId,
  onAttach,
}: Pick<AddVolumeDialogProps, "handleClose" | "instanceId" | "onAttach">) {
  const result = useQuery({
    queryKey: [getAvailableUserVolumes.name],
    queryFn: async () => {
      const { status, data } = await getAvailableUserVolumes();
      if (status !== "success") throw "Error fetching user volumes!";

      return data;
    },
  });

  const { enqueueSnackbar } = useSnackbar();
  const handleError = useHandleError();
  const [, setBackdropState] = useBackdropContext();
  const invalidateQueries = useInvalidateQueries();

  const { mutateAsync: executeAttachVolume } = useMutation({
    mutationFn: async (payload: AttachVolumeRequest) => {
      setBackdropState({
        open: true,
        msg: "Attaching volume",
      });
      const { status, data } = await attachVolume(payload);

      setBackdropState({
        open: false,
        msg: "",
      });

      if (status !== "success") {
        enqueueSnackbar(data, { variant: "error" });
        throw data;
      }

      enqueueSnackbar(data, { variant: "success" });
    },
    onError: handleError,
    onSettled: () => {
      setBackdropState({
        open: false,
        msg: "",
      });
    },
  });

  return (
    <Formik
      initialValues={{
        instance: instanceId || "",
        volume: "",
      }}
      validationSchema={attachVolumeSchema}
      onSubmit={async (payload, { setSubmitting }) => {
        await executeAttachVolume(payload);
        invalidateQueries("attach-volume");
        setSubmitting(false);
        if (onAttach) onAttach();
      }}
      validateOnBlur
    >
      <FormikForm width="100%">
        <DialogContent>
          <Query result={result} onSuccess={(volumes) => <AttachVolumeForm volumes={volumes} />} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <FormikSubmitButton variant="contained" type="submit">
            Attach
          </FormikSubmitButton>
        </DialogActions>
      </FormikForm>
    </Formik>
  );
}

interface AttachVolumeFormProps {
  volumes: Volume[];
}

function AttachVolumeForm({ volumes }: AttachVolumeFormProps) {
  return (
    <Stack>
      <h5>SELECT VOLUME TO ATTACH</h5>
      <FormikSelect id="volume" name="volume">
        {volumes.map((volume) => (
          <MenuItem key={volume.id} value={volume.id}>
            {volume.name} - {volume.size}GB
          </MenuItem>
        ))}
      </FormikSelect>
    </Stack>
  );
}
