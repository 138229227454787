import { Button, Stack, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useBackdropContext } from "@nc/neoscloud-common-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useHandleError } from "Hooks/useHandleError";
import { getUserInstances } from "Services/api/instances/instances";
import { getMachineBillingDetails } from "Services/api/neosaccount/neosaccount";
import { Volume } from "Services/api/volumes/interfaces";
import { deleteVolume, detachVolume, getAvailableUserVolumes, getUserVolumes } from "Services/api/volumes/volumes";
import { AddVolumeDialog } from "Shared/AddVolumeDialog/AddVolumeDialog";
import { Query } from "Shared/Query/Query";
import { confirmWrapper } from "Utils/confirmWrapper";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { useState } from "react";

export function Volumes(): JSX.Element {
  const result = useQuery({
    queryKey: [getUserVolumes.name],
    queryFn: async () => {
      const { status, data } = await getUserVolumes();
      if (status !== "success") throw "Error fetching user volumes!";

      return data;
    },
  });

  return <Query result={result} onSuccess={(volumes) => <VolumesSection volumes={volumes} />} />;
}

interface VolumesSectionProps {
  volumes: Volume[];
}

function VolumesSection({ volumes }: VolumesSectionProps) {
  const [vols, setVols] = useState(volumes);
  const [openAddDialog, setOpenAddDialog] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const handleError = useHandleError();
  const queryClient = useQueryClient();
  const [, setBackdropState] = useBackdropContext();

  const { mutateAsync: detachVolumeAsync } = useMutation({
    mutationFn: async (id: string) => {
      setBackdropState({ open: true, msg: "Detaching volume..." });
      const { status, data } = await detachVolume({
        volume: id,
      });

      if (status == "success") {
        enqueueSnackbar("Volume detached successfully", { variant: "success" });
        const volumes = vols.map((volume) => {
          if (volume.id === id) return data;
          return volume;
        });
        setVols(volumes);
        queryClient.setQueryData<Volume[]>([getUserVolumes.name], volumes);

        void queryClient.invalidateQueries({
          queryKey: [getUserInstances.name],
          exact: true,
        });
        void queryClient.invalidateQueries({
          queryKey: [getAvailableUserVolumes.name],
          exact: true,
        });
      } else {
        enqueueSnackbar("Error detaching volume", { variant: "error" });
      }
    },
    onError: handleError,
    onSettled: () => setBackdropState({ open: false, msg: "" }),
  });

  const { mutateAsync: deleteVolumeAsync } = useMutation({
    mutationFn: async (id: string) => {
      setBackdropState({ open: true, msg: "Deleting volume..." });
      const { status, data } = await deleteVolume(id);

      if (status == "success") {
        enqueueSnackbar(data, { variant: "success" });
        const volumes = vols.filter((volume) => volume.id !== id);
        setVols(volumes);
        queryClient.setQueryData<Volume[]>([getUserVolumes.name], volumes);
        void queryClient.invalidateQueries({
          queryKey: [getAvailableUserVolumes.name],
          exact: true,
        });
        void queryClient.invalidateQueries({
          queryKey: [getMachineBillingDetails.name],
          exact: true,
        });
      } else {
        enqueueSnackbar(data.message, { variant: "error" });
      }
    },
    onError: handleError,
    onSettled: () => setBackdropState({ open: false, msg: "" }),
  });

  return (
    <section>
      <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="space-between">
        <h2>Volumes</h2>
        <Button variant="contained" onClick={() => setOpenAddDialog(true)}>
          Add Volume
        </Button>
      </Stack>

      {vols.length > 0 ? (
        <Table sx={{ minWidth: 550, textAlign: "center" }} aria-label="Volumes">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Attached To</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {vols.map((volume) => (
              <VolumeRow
                key={volume.id}
                volume={volume}
                onDetach={() => void detachVolumeAsync(volume.id)}
                onDelete={() => void deleteVolumeAsync(volume.id)}
              />
            ))}
          </TableBody>
        </Table>
      ) : (
        <div>You don&apos;t have any volumes...</div>
      )}
      <AddVolumeDialog
        open={openAddDialog}
        handleClose={() => setOpenAddDialog(false)}
        onAdd={(volume) => {
          if (!volume) return;
          const volumes = [...vols, volume];
          setVols(volumes);
          queryClient.setQueryData<Volume[]>([getUserVolumes.name], volumes);
          void queryClient.invalidateQueries({
            queryKey: [getUserInstances.name],
            exact: true,
          });
          void queryClient.invalidateQueries({
            queryKey: [getAvailableUserVolumes.name],
            exact: true,
          });
          void queryClient.invalidateQueries({
            queryKey: [getMachineBillingDetails.name],
            exact: true,
          });
          setOpenAddDialog(false);
        }}
      />
    </section>
  );
}

interface VolumeRowProps {
  volume: Volume;
  onDetach: () => void;
  onDelete: () => void;
}

function VolumeRow({ volume, onDetach, onDelete }: VolumeRowProps) {
  const confirm = useConfirm();
  return (
    <TableRow>
      <TableCell>{volume.name}</TableCell>
      <TableCell>{volume.description}</TableCell>
      <TableCell>{volume.size}GB</TableCell>
      <TableCell>{volume.status}</TableCell>
      <TableCell>{volume.attached_to}</TableCell>
      <TableCell>
        {volume.attached_to ? (
          <Button
            color="primary"
            variant="contained"
            onClick={() =>
              confirmWrapper(
                confirm({
                  title: `Are you sure you want to detach volume ${volume.name} from ${volume.attached_to}?`,
                  confirmationText: "Detach",
                }),
                onDetach
              )
            }
          >
            Detach
          </Button>
        ) : (
          <Button
            color="error"
            variant="contained"
            onClick={() =>
              confirmWrapper(
                confirm({
                  title: `Are you sure you want to delete volume: ${volume.name}?`,
                  description:
                    "If you delete this volume you will still be charged at the end of the month what you owe based on the current month-to-date usage.",
                  confirmationText: "Delete",
                }),
                onDelete
              )
            }
          >
            Delete
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
}
