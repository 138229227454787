import { array, DecoderType, number, object, positiveInteger, string } from "decoders";
import { jsendResponseDecoder, jsendSuccessDecoder } from "Utils/jsend";
import { errorKeyResponseDecoder } from "../interfaces";

const volumeDecoder = object({
  id: string,
  name: string,
  description: string,
  size: positiveInteger,
  status: string,
  attached_to: string,
});

export type Volume = DecoderType<typeof volumeDecoder>;

export const getUserVolumesResponseDecoder = jsendResponseDecoder(array(volumeDecoder), string);

export type GetUserVolumesResponse = DecoderType<typeof getUserVolumesResponseDecoder>;

export interface CreateVolumeRequest {
  name: string;
  description: string;
  size: number;
  instance_id: string;
}

export const createUserVolumesResponseDecoder = jsendResponseDecoder(volumeDecoder, errorKeyResponseDecoder);

export type CreateUserVolumesResponse = DecoderType<typeof createUserVolumesResponseDecoder>;

export const deleteVolumeResponseDecoder = jsendResponseDecoder(string, errorKeyResponseDecoder);

export type DeleteVolumeResponse = DecoderType<typeof deleteVolumeResponseDecoder>;

export interface AttachVolumeRequest {
  instance: string;
  volume: string;
}

export const attachVolumeResponseDecoder = jsendSuccessDecoder(string);

export type AttachVolumeResponse = DecoderType<typeof attachVolumeResponseDecoder>;

export interface DetachVolumeRequest {
  volume: string;
}

export const detachVolumeResponseDecoder = jsendSuccessDecoder(volumeDecoder);

export type DetachVolumeResponse = DecoderType<typeof detachVolumeResponseDecoder>;

const volumeOptionsDecoder = object({
  storages: array(
    object({
      id: positiveInteger,
      name: string,
      size: positiveInteger,
      costPerMonth: number,
      costPerHour: number,
    })
  ),
  storageMaxHours: number,
  storagePricePerGB: number,
  instances: array(
    object({
      name: string,
      reference: string,
    })
  ),
});

export type VolumeOptions = DecoderType<typeof volumeOptionsDecoder>;

export const volumeOptionsResponseDecoder = jsendResponseDecoder(volumeOptionsDecoder, string);

export type VolumeOptionsResponse = DecoderType<typeof volumeOptionsResponseDecoder>;
