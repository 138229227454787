import { FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { CreateInstanceOptions, Plan } from "Services/api/instances/interfaces";
import { useState } from "react";
import PriceInfo from "./PriceInfo";
import SelectCard from "./SelectCard";
import { FormData } from "./interfaces";

export default function ChoosePlanSection({
  plans,
  onChange,
}: {
  plans: CreateInstanceOptions["plans"];
  onChange: (plan: FormData["plan"]) => void;
}) {
  const [index, setIndex] = useState(0);
  const [plan, setPlan] = useState<Plan>(plans[index]);
  const [flavorId, setFlavorId] = useState(plan.options[0].flavorId);

  return (
    <section>
      <h2>Choose a plan</h2>
      <Stack spacing={2}>
        <section>
          <FormControl>
            <RadioGroup
              row
              name="plan-types-group"
              value={index}
              onChange={({ target: { value } }) => {
                const newValue = Number(value);
                setIndex(newValue);
                setPlan(plans[newValue]);
                setFlavorId(plans[newValue].options[0].flavorId);
                onChange({ name: plans[newValue].name, option: plans[newValue].options[0] });
              }}
            >
              {plans.map((plan, index) => (
                <FormControlLabel key={plan.name} value={index} control={<Radio />} label={plan.name} />
              ))}
            </RadioGroup>
          </FormControl>
        </section>
        <section>
          <p>{plan.description}</p>
        </section>
        <section>
          {plan.options.map((planOption) => (
            <SelectCard
              key={planOption.flavorId}
              onClick={() => {
                setFlavorId(planOption.flavorId);
                onChange({ name: plans[index].name, option: planOption });
              }}
              isSelected={flavorId === planOption.flavorId}
              dataTestid={planOption.name}
              topElement={
                <PriceInfo costPerMonth={planOption.costPerMonth} costPerHour={planOption.costPerHour}></PriceInfo>
              }
              bottomElement={
                <div>
                  <div>{planOption.cpu}</div>
                  <div>{planOption.memory.display}</div>
                  <div>{planOption.disk.display}</div>
                </div>
              }
            ></SelectCard>
          ))}
        </section>
        <Typography sx={{ fontStyle: "italic", textAlign: "justify" }}>
          You will never be billed more than the monthly cost of your Instance. All Instances are billed hourly up to a
          monthly cap of 672 hours (the number of hours in 4 weeks). If you use your server for fewer than 672 hours
          during the month, you will be billed for each hour that you used it. If you use your server for more than 672
          hours that month, you will be billed at the monthly cost.
        </Typography>
      </Stack>
    </section>
  );
}
