import ClearIcon from "@mui/icons-material/Clear";
import { CssBaseline } from "@mui/material";
import { grey } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BackdropProvider } from "@nc/neoscloud-common-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import MainContainer from "Containers/MainContainer/MainContainer";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarKey, SnackbarProvider } from "notistack";
import { Billing } from "Pages/Billing/Billing";
import CreateMachine from "Pages/CreateMachine/CreateMachine";
import { Dashboard } from "Pages/Dashboard/Dashboard";
import { Keys } from "Pages/Keys/Keys";
import { Login } from "Pages/Login/Login";
import { SecurityGroup } from "Pages/SecurityGroups/SecurityGroup";
import { SecurityGroups } from "Pages/SecurityGroups/SecurityGroups";
import { Volumes } from "Pages/Volumes/Volumes";
import { createRef } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import useWebSocket from "react-use-websocket";
import { USER_WS_URL } from "Utils/envVariables";
import baselineOverrides from "./baselineOverrides.css";

const theme = createTheme({
  palette: {
    primary: {
      main: grey[900],
      light: "#484848",
      dark: "#000000",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: baselineOverrides,
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
    },
  },
});

export function App() {
  const notistackRef = createRef<SnackbarProvider>();
  /* istanbul ignore next */
  const onClickDismiss = (key: SnackbarKey) => () => {
    (notistackRef.current as SnackbarProvider).closeSnackbar(key);
  };

  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <SnackbarProvider
            maxSnack={3}
            ref={notistackRef}
            hideIconVariant
            action={(key) => (
              <IconButton onClick={onClickDismiss(key)} aria-label="dismiss alert" component="span">
                <ClearIcon sx={{ color: "#ffffff" }} />
              </IconButton>
            )}
          >
            <BackdropProvider>
              <Router>
                <Routes>
                  <Route path="/" element={<LoggedIn Element={Dashboard} />}></Route>
                  <Route path="/login" element={<Login />}></Route>
                  <Route path="/keys" element={<LoggedIn Element={Keys} />}></Route>
                  <Route path="/volumes" element={<LoggedIn Element={Volumes} />}></Route>
                  <Route path="/machines/new" element={<LoggedIn Element={CreateMachine} />}></Route>
                  <Route path="/billing" element={<LoggedIn Element={Billing} />}></Route>
                  <Route path="/security-groups">
                    <Route path="" element={<LoggedIn Element={SecurityGroups} />} />
                    <Route path=":id" element={<LoggedIn Element={SecurityGroup} />} />
                  </Route>
                </Routes>
              </Router>
            </BackdropProvider>
          </SnackbarProvider>
        </QueryClientProvider>
      </ConfirmProvider>
    </ThemeProvider>
  );
}

function LoggedIn({ Element }: { Element: () => JSX.Element }) {
  useWebSocket(USER_WS_URL, {
    share: true,
  });

  return (
    <MainContainer>
      <Element />
    </MainContainer>
  );
}
