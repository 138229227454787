import { useQueryClient } from "@tanstack/react-query";
import { getUserInstances } from "Services/api/instances/instances";
import { getUserKeys } from "Services/api/keypairs/keypairs";
import { getMachineBillingDetails } from "Services/api/neosaccount/neosaccount";
import { getAvailableUserVolumes, getUserVolumes } from "Services/api/volumes/volumes";

type InvalidatePath = "create-machine" | "delete-machine" | "add-volume" | "attach-volume";

export function useInvalidateQueries() {
  const queryClient = useQueryClient();

  return (invalidatePath: InvalidatePath) => {
    const queryKeys: string[] = [];
    if (invalidatePath === "create-machine") {
      queryKeys.push(getUserInstances.name);
      queryKeys.push(getUserVolumes.name);
      queryKeys.push(getUserKeys.name);
      queryKeys.push(getMachineBillingDetails.name);
    } else if (invalidatePath === "delete-machine") {
      queryKeys.push(getUserVolumes.name);
      queryKeys.push(getAvailableUserVolumes.name);
      queryKeys.push(getMachineBillingDetails.name);
    } else if (invalidatePath === "add-volume") {
      queryKeys.push(getUserInstances.name);
      queryKeys.push(getUserVolumes.name);
      queryKeys.push(getAvailableUserVolumes.name);
      queryKeys.push(getMachineBillingDetails.name);
    } else if (invalidatePath === "attach-volume") {
      queryKeys.push(getUserInstances.name);
      queryKeys.push(getUserVolumes.name);
    }

    for (const queryKey of queryKeys) {
      void queryClient.invalidateQueries({
        queryKey: [queryKey],
        exact: true,
      });
    }
  };
}
